.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  height: 52px;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.02), 0 2px 10px 0 rgba(0, 0, 0, 0.04); }
  .toolbar.simple-toolbar {
    height: 66px; }
  .toolbar.transparent {
    background-image: none;
    background-color: transparent;
    box-shadow: none; }
  .toolbar .app-link {
    color: rgba(0, 0, 0, 0.8);
    display: inline-block;
    font-size: 14px;
    line-height: 29px;
    margin-left: 12px;
    padding: 0 12px;
    transition: all 0.4s ease; }
    .toolbar .app-link:hover {
      color: #00ff52; }
    .toolbar .app-link.warning {
      display: flex;
      align-items: flex-end; }
      .toolbar .app-link.warning span {
        margin-left: 10px;
        padding: 0;
        display: none; }
        .toolbar .app-link.warning span svg {
          fill: darkorange !important;
          color: darkorange !important;
          width: 12px !important; }
      .toolbar .app-link.warning:hover {
        color: darkorange;
        cursor: not-allowed; }
        .toolbar .app-link.warning:hover span {
          display: inline-block; }

.border-bottom {
  border-bottom: 1px solid #d4d4d4; }

.border-right {
  border-right: 1px solid #d4d4d4; }

.toolbar-group {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.toolbar-group-right {
  display: flex;
  align-items: center; }

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 110px; }
  .simple-toolbar .logo-container {
    width: 160px; }

.toolbar-title {
  position: absolute;
  width: 100%;
  z-index: -1;
  line-height: 65px;
  text-align: center;
  margin: 0; }

.seperator {
  background-color: #d4d4d4;
  height: 46px;
  width: 1px;
  margin: 0 6px; }

.toolbar-logout {
  margin-left: 6px;
  margin-right: 16px; }

.app-menu-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 24px;
  background: transparent;
  color: #555;
  font-size: 16px; }
  .app-menu-btn:focus, .app-menu-btn:hover {
    background: transparent;
    color: #333; }

span.plan-type {
  text-decoration: underline; }
