@import '../../../../../../_variables.scss';

.bookingPaid {
  display: flex;
  align-items: center;
}

.tax {
  display: flex;
  align-items: center;
}

.stripe {
  display: flex;
  align-items: center;
}

.currency {
  display: flex;
  align-items: center;
  margin: 20px 0 30px;
}

.currency h4 {
  margin-right: 20px;
}

.stock {
  margin: 30px 0;
}

.stock span {
  font-family: 'Raleway', 'Karla', sans-serif !important;
  font-weight: bold !important;
}

.button {
  height: 35px;
  padding: 0 40px;
  font-size: 20px;
  font-weight: bold;
  color: #ffbddb;
  background-color: $primary-color !important;
}

.stock label {
  margin-left: 0 !important;
}

.container form {
  margin-left: 30px;
  margin-bottom: 40px;
}

.container label {
  margin-left: 20px;
}

.container input {
  margin-left: 10px;
}

.settingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 24px 10px 12px 0px;
}

.settingField {
  display: flex;
  width: 100%;
  position: relative;
  margin: 24px 10px 12px 0px;

  align-items: center;
  justify-content: space-between;
}

.settingTitle {
  border-bottom: 2px solid #c8c8c8;
  width: 100%;
  padding-bottom: 15px;
  color: $primary-color;
  font-size: 24px;
  font-family: Montserrat;
}

.field {
  width: 100%;
  margin: 24px 10px 12px 0px;
}

.submitBtn {
  width: fit-content;
  background-color: $primary-color !important;
}
