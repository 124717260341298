.ratings-modal-content .ratings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 25px; }
  .ratings-modal-content .ratings svg {
    opacity: 0.5;
    width: 18px !important;
    height: 18px !important; }
  .ratings-modal-content .ratings .rating {
    display: flex;
    align-items: center; }
    .ratings-modal-content .ratings .rating .bar {
      width: 100px;
      background-color: lightgray;
      margin: 0 10px;
      height: 4px;
      border-radius: 5px; }
      .ratings-modal-content .ratings .rating .bar .progress {
        background-color: black;
        z-index: 9999;
        height: 4px;
        border-radius: 5px; }
  .ratings-modal-content .ratings .average-rating-container {
    display: flex;
    margin: 10px 0;
    font-weight: 600; }
    .ratings-modal-content .ratings .average-rating-container .text,
    .ratings-modal-content .ratings .average-rating-container .rating {
      margin-right: 5px; }

.ratings-modal-content .feedback .feedback-fields {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 320px; }
  .ratings-modal-content .feedback .feedback-fields .field {
    flex: 1;
    min-width: 50%;
    word-break: break-all; }
    .ratings-modal-content .feedback .feedback-fields .field svg {
      opacity: 0.5;
      width: 18px !important;
      height: 18px !important; }

.ratings-modal-content .feedback .navigation {
  display: flex;
  justify-content: space-between;
  align-items: center; }
