.webhook_instruction_container {
  margin-top: 1rem;
  > .helper_text {
    font-size: 0.7rem;
    color: grey;
    margin: 0.2rem 0;
    padding: 0 0.5rem;
  }
  .webhook_url {
    padding: 0.5rem;
    margin: 0.2rem 0;
    width: fit-content;
    border: 1px solid lightgrey;
    border-radius: 2px;
    cursor: pointer;
  }

  .webhook_url:hover {
    background: #e8e8e8;
  }
}

.subscription-layout-container {
  display: flex;
}
