.input_container {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.coupon_code_input {
	margin-left: 0 !important;
}

.discount_input {
	width: 170px;
	margin-left: 20px;
}

.buttons_container {
	display: flex;
}

.button {
	margin-left: 10px;
	border: 1px solid black;
}