.form-container.send-sms {
  overflow: hidden;
  position: relative; }

.radio-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 320px;
  margin: 12px; }

.confirmation-message {
  font-size: 15px;
  margin-top: 36px;
  margin-bottom: 0;
  text-align: center; }

.modal-controls-container {
  position: absolute;
  right: 0;
  top: 0; }

.sms-left-container {
  margin-top: 10px;
  text-align: center;
  float: left; }

.sms-left-title {
  font-size: 0.75em;
  text-decoration: underline;
  font-weight: 600; }

.sms-left-number {
  margin-top: -28px;
  font-size: 2em;
  font-weight: 700; }

.sms-button-container {
  margin-left: 70px; }

.intro-wrapper {
  margin: 20px 0px; }

.page-subTitle {
  text-transform: capitalize;
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 24px;
  margin: 0; }

.page-description {
  font-size: 16px;
  letter-spacing: 0.64px;
  color: #000000;
  line-height: 22px; }

.section-wrapper {
  margin: 30px 0px 0px 0px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); }

.section-wrapper .section {
  padding: 10px 60px; }

.section-border {
  border-right: 1px solid #b5b5b5; }

.section h6 {
  text-align: center; }

.section p {
  min-height: 190px;
  text-align: left; }

.section img {
  max-width: 100%;
  width: 200px;
  height: auto;
  display: block;
  margin: 0 auto; }

@media screen and (max-width: 1550px) {
  .section-wrapper .section {
    padding: 10px 20px; } }

@media screen and (max-width: 860px) {
  .section-wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr)); }
  .intro-wrapper {
    overflow-y: scroll; }
  .section-border {
    border-right: 0px; } }
