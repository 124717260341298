.contacts-form {
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 700px;
  color: #333; }
  .contacts-form h5 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    text-align: center; }
  .contacts-form .input-info {
    display: block;
    font-size: 11px;
    top: -10px;
    margin-top: -8px;
    text-align: right;
    margin-bottom: 15px; }
  .contacts-form .import-multiple-link {
    text-decoration: underline;
    cursor: pointer; }
  .contacts-form .instructions {
    text-align: center; }
    .contacts-form .instructions span {
      text-decoration: underline;
      cursor: pointer; }
  .contacts-form .contacts-form-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%; }
    .contacts-form .contacts-form-heading button {
      padding: 0 !important;
      margin: 0 !important;
      width: auto !important;
      height: auto !important; }
    .contacts-form .contacts-form-heading h5 {
      font-weight: 200;
      font-size: 26px;
      text-align: left;
      display: inline;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .contacts-form .divider {
    background-color: #959595;
    width: 100%;
    height: 2px; }
  .contacts-form .contacts-form-content {
    padding: 15px;
    max-width: 65%; }
    .contacts-form .contacts-form-content .divider {
      height: 275px;
      width: 1px;
      margin-top: 84px;
      margin: 0 25px; }
    .contacts-form .contacts-form-content .profile-fieldset > div {
      padding: 0 12px; }
    .contacts-form .contacts-form-content .contact-counter {
      margin: 20px;
      color: #010101; }
    .contacts-form .contacts-form-content .counter-number {
      color: #010101;
      margin: 5px;
      font-size: 35px; }
    .contacts-form .contacts-form-content .contacts-form-fields {
      width: 45%; }
      .contacts-form .contacts-form-content .contacts-form-fields .select-field-wrapper,
      .contacts-form .contacts-form-content .contacts-form-fields .beezer-mobile-text-field-container {
        width: 100%; }
    .contacts-form .contacts-form-content .import-contacts-container .error-text {
      color: orangered;
      text-align: center; }
    .contacts-form .contacts-form-content .import-contacts-container .upload-container .dropzone {
      min-height: 335px;
      justify-content: center;
      width: 100%;
      border: 1px solid #c4c4c4;
      border-radius: 8px;
      padding: 7px;
      background-color: #f4f4f4;
      text-align: center;
      cursor: copy;
      display: flex;
      align-items: center;
      flex-direction: column; }
      .contacts-form .contacts-form-content .import-contacts-container .upload-container .dropzone svg {
        color: #c1c1c1 !important;
        height: 75px !important;
        width: 75px !important; }
      .contacts-form .contacts-form-content .import-contacts-container .upload-container .dropzone .dropzone-text {
        color: #010101; }
        .contacts-form .contacts-form-content .import-contacts-container .upload-container .dropzone .dropzone-text u {
          cursor: pointer; }
      .contacts-form .contacts-form-content .import-contacts-container .upload-container .dropzone .dropzone-icon {
        margin: 5px;
        color: #c1c1c1; }
      .contacts-form .contacts-form-content .import-contacts-container .upload-container .dropzone .dropzone-title {
        font-size: 30px;
        text-align: center;
        color: #c1c1c1; }
