.addon_container {
	display: grid;
	grid-template-columns: repeat(4, 200px);
	gap: 20px;
	justify-content: center;
}

.addon_container > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
	background-color: #F6F6F6;
	border-radius: 5px;
	position: relative;
}

.addon_container > div > svg {
	cursor: pointer;
	position: absolute;
	top: 6px;
	right: 6px;
}