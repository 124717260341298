.qr-container {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .qr-container [class^='col-xs-'],
  .qr-container a {
    text-align: center; }

.qr-item {
  margin: auto; }

.qr-image {
  align-items: center;
  display: flex;
  flex-direction: column; }

.download-qr {
  text-align: center;
  font-size: 14px;
  padding: 6px 0;
  margin: 0;
  display: block;
  color: #787878;
  cursor: pointer; }
  .download-qr:focus {
    outline: none; }
