/* Set content for font-families */
.text-editor {
  margin-top: 15px;
  max-width: 100%;
  position: relative; }

.ql-snow .ql-editor {
  background: rgba(0, 0, 0, 0.12); }

.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif; }

.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif; }

.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New'; }

.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif; }

.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif; }

.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif; }

.ql-font-times-new-roman,
.ql-font span[data-value='times-new-roman']::before {
  font-family: 'Times New Roman', Times, serif; }

.ql-font-impact,
.ql-font span[data-value='impact']::before {
  font-family: Impact, Charcoal, sans-serif; }

.ql-font-modak,
.ql-font span[data-value='modak']::before {
  font-family: 'Modak', cursive; }

.ql-font-teko,
.ql-font span[data-value='teko']::before {
  font-family: 'Teko', sans-serif; }

.ql-font-fondamento,
.ql-font span[data-value='fondamento']::before {
  font-family: 'Fondamento', cursive; }

.ql-font-titillium-web,
.ql-font span[data-value='titillium-web']::before {
  font-family: 'Titillium Web', cursive; }

.ql-font-roboto,
.ql-font span[data-value='roboto']::before {
  font-family: 'Roboto', sans-serif; }

.ql-font-open-sans,
.ql-font span[data-value='open-sans']::before {
  font-family: 'Open Sans', sans-serif; }

.ql-font-lato,
.ql-font span[data-value='lato']::before {
  font-family: 'Lato', sans-serif; }

.ql-font-lexend,
.ql-font span[data-value='lexend']::before {
  font-family: 'Lexend', sans-serif;
  font-weight: 300; }

.ql-font-oswald,
.ql-font span[data-value='oswald']::before {
  font-family: 'Oswald', sans-serif; }

.ql-font-source-sans-pro,
.ql-font span[data-value='source-sans-pro']::before {
  font-family: 'Source Sans Pro', sans-serif; }

.ql-font-montserrat,
.ql-font span[data-value='montserrat']::before {
  font-family: 'Montserrat', sans-serif; }

.ql-font-raleway,
.ql-font span[data-value='raleway']::before {
  font-family: 'Raleway', sans-serif; }

.ql-font-pt-sans,
.ql-font span[data-value='pt-sans']::before {
  font-family: 'PT Sans', sans-serif; }

.ql-font-lora,
.ql-font span[data-value='lora']::before {
  font-family: 'Lora', serif; }

.ql-font-nunito-sans,
.ql-font span[data-value='nunito-sans']::before {
  font-family: 'Nunito Sans', sans-serif; }

.ql-font-concert-one,
.ql-font span[data-value='concert-one']::before {
  font-family: 'Concert One', cursive; }

.ql-font-prompt,
.ql-font span[data-value='prompt']::before {
  font-family: 'Prompt', sans-serif; }

.ql-font-alegreya,
.ql-font span[data-value='alegreya']::before {
  font-family: 'Alegreya', serif; }

.ql-font-b612,
.ql-font span[data-value='b612']::before {
  font-family: 'B612', sans-serif; }

.ql-font-varela,
.ql-font span[data-value='varela']::before {
  font-family: 'Varela', sans-serif; }

.ql-font-vollkorn,
.ql-font span[data-value='vollkorn']::before {
  font-family: 'Vollkorn', serif; }

.ql-font-crimson-text,
.ql-font span[data-value='crimson-text']::before {
  font-family: 'Crimson Text', serif; }

.ql-font-cairo,
.ql-font span[data-value='cairo']::before {
  font-family: 'Cairo', sans-serif; }

.ql-font-biorhyme,
.ql-font span[data-value='biorhyme']::before {
  font-family: 'BioRhyme', serif; }

.ql-font-playfair-display,
.ql-font span[data-value='playfair-display']::before {
  font-family: 'Playfair Display', serif; }

.ql-font-archivo,
.ql-font span[data-value='archivo']::before {
  font-family: 'Archivo', sans-serif; }

.ql-font-fjalla-one,
.ql-font span[data-value='fjalla-one']::before {
  font-family: 'Fjalla One', sans-serif; }

.ql-font-rubik,
.ql-font span[data-value='rubik']::before {
  font-family: 'Rubik', sans-serif; }

.ql-font-ubuntu,
.ql-font span[data-value='ubuntu']::before {
  font-family: 'Ubuntu', sans-serif; }

.ql-font-work-sans,
.ql-font span[data-value='work-sans']::before {
  font-family: 'Work Sans', sans-serif; }

.ql-font-gill-sans,
.ql-font span[data-value='gill-sans']::before {
  font-family: 'Gill Sans', sans-serif; }

.ql-font-museo,
.ql-font span[data-value='museo']::before {
  font-family: 'Museo', sans-serif; }

/* Set content for sizes */
.ql-editor .ql-size-extra-small,
.ql-editor .ql-size span[data-value='extra-small']::before {
  font-size: 10px !important; }

.ql-editor .ql-size-small,
.ql-editor .ql-size span[data-value='small']::before {
  font-size: 15px !important; }

.ql-editor .ql-size-medium,
.ql-editor .ql-size span[data-value='medium']::before {
  font-size: 20px !important; }

.ql-editor .ql-size-large,
.ql-editor .ql-size span[data-value='large']::before {
  font-size: 25px !important; }

.ql-editor .ql-size-16px,
.ql-editor .ql-size span[data-value='16px']::before {
  font-size: 16px !important; }

.ql-editor .ql-size-17px,
.ql-editor .ql-size span[data-value='17px']::before {
  font-size: 17px !important; }

.ql-editor .ql-size-32px,
.ql-editor .ql-size span[data-value='32px']::before {
  font-size: 32px !important; }

.ql-editor .ql-size-36px,
.ql-editor .ql-size span[data-value='36px']::before {
  font-size: 36px !important; }

.ql-editor .ql-size-42px,
.ql-editor .ql-size span[data-value='42px']::before {
  font-size: 42px !important; }

.ql-editor .ql-size-50px,
.ql-editor .ql-size span[data-value='50px']::before {
  font-size: 50px !important; }

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 2em; }

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 4em; }

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 6em; }

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 8em; }

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 10em; }

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 12em; }

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 14em; }

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 16em; }

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 18em; }

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 2.5em !important; }

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 4.5em !important; }

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 6.5em !important; }

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 8.5em !important; }

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 10.5em !important; }

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 12.5em !important; }

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 14.5em !important; }

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 16.5em !important; }

/* Toolbar font size */
.ql-toolbar .ql-size-extra-small,
.ql-toolbar .ql-size span[data-value='extra-small']::before,
.ql-toolbar .ql-size-small,
.ql-toolbar .ql-size span[data-value='small']::before,
.ql-toolbar .ql-size-medium,
.ql-toolbar .ql-size span[data-value='medium']::before,
.ql-toolbar .ql-size-large,
.ql-toolbar .ql-size span[data-value='large']::before,
.ql-toolbar .ql-size-32px,
.ql-toolbar .ql-size span[data-value='32px']::before,
.ql-toolbar .ql-size-36px,
.ql-toolbar .ql-size span[data-value='36px']::before,
.ql-toolbar .ql-size-42px,
.ql-toolbar .ql-size span[data-value='42px']::before,
.ql-toolbar .ql-size-50px,
.ql-toolbar .ql-size span[data-value='50px']::before {
  font-size: 15px !important; }

.ql-snow .ql-color-picker.ql-color .ql-picker-item[data-value='custom-color'] {
  border-radius: 50%;
  outline: none;
  background-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet); }

.ql-snow
.ql-color-picker.ql-color
.ql-picker-item[data-value='custom-color']:hover {
  border-color: transparent !important; }

.ql-snow
.ql-color-picker.ql-background
.ql-picker-item[data-value='custom-color'] {
  border-radius: 50%;
  outline: none;
  background-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet); }

.ql-snow
.ql-color-picker.ql-background
.ql-picker-item[data-value='custom-color']:hover {
  border-color: transparent !important; }

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  max-height: 200px;
  overflow: scroll; }

.ql-snow .ql-tooltip.ql-editing {
  left: 0 !important; }

.react-color-picker-text-editor {
  position: absolute;
  top: 65px;
  left: 80px;
  z-index: 1000; }

.react-color-picker-text-editor-hidden {
  display: none;
  visibility: hidden; }

.react-color-picker-text-editor-done {
  display: inline-block;
  position: relative;
  bottom: 25px;
  left: 160px;
  cursor: pointer;
  font-weight: 900; }

.ql-align-justify {
  white-space: pre-line; }

#scroll-dialog-description img {
  width: 100%; }

.ql-saveContent {
  width: 100% !important;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  height: 100% !important;
  background-color: var(--primaryColor) !important; }

.ql-saveContent:disabled {
  background-color: #cccccc !important;
  cursor: not-allowed !important;
  color: #ffffff !important; }

.ql-video {
  width: 100%;
  height: 680px; }
