.social-buttons-container {
  margin: 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap; }

.title-container > h3 {
  margin-top: 0; }

.title-container {
  margin: 10px; }

.social-button-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 6px 5px;
  cursor: pointer; }

.social-title {
  margin-top: 5px;
  font-weight: 501;
  font-size: 16px; }

.social-title.facebook {
  color: #3b5998; }

.social-title.google {
  color: #da4f46; }

.social-title.linkedin {
  color: #117bb5; }

.social-title.twitter {
  color: #1daced; }

.social-button-link > .label {
  margin-top: 5px;
  font-weight: 700;
  font-size: 12px;
  padding-left: 14px; }

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 200px;
  border-radius: 3px;
  transition: all 300ms ease-out; }

.social-icon.facebook {
  background-color: #3b5998; }

.label.facebook {
  color: #3b5998; }

.social-button-link:hover > .social-icon.facebook {
  background-color: #2f4779; }

.social-button-link:hover > .label.facebook {
  color: #2f4779; }

.social-icon.google {
  background-color: #da4f46; }

.label.google {
  color: #da4f46; }

.social-button-link:hover > .social-icon.google {
  background-color: #ae3f38; }

.social-button-link:hover > .label.google {
  color: #ae3f38; }

.social-icon.linkedin {
  background-color: #117bb5; }

.label.linkedin {
  color: #117bb5; }

.social-button-link:hover > .social-icon.linkedin {
  background-color: #0d6290; }

.social-button-link:hover > .label.linkedin {
  color: #0d6290; }

.social-icon.twitter {
  background-color: #1daced; }

.label.twitter {
  color: #1daced; }

.social-button-link:hover > .social-icon.twitter {
  background-color: #1789bd; }

.social-button-link:hover > .label.twitter {
  color: #1789bd; }
