.add-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333 !important; }
  .add-field .small-toggle {
    padding: 10px 7px; }
  .add-field h5 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    text-align: center; }
  .add-field .input-info {
    display: block;
    font-size: 11px;
    top: -10px;
    margin-top: -8px;
    text-align: right;
    margin-bottom: 15px; }
