.upgrade-plan-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 300px;
  max-height: 100%;
  padding: 40px;
  text-align: center; }

.host_container_text {
  width: 90%;
  margin-left: 5%;
  padding: 0 5px; }
  .host_container_text > .title {
    font-size: 0.7rem;
    color: grey;
    margin: 0.2rem 0;
    padding: 0 0.5rem; }
  .host_container_text .host_record_text {
    padding: 0.5rem;
    margin: 0.2rem 0;
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 2px;
    overflow-wrap: break-word;
    cursor: pointer; }
  .host_container_text .host_record_text:hover {
    background: #e8e8e8; }

.custom-domain-message {
  position: relative;
  padding: 0 15px;
  margin: 1rem 0;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  display: block; }
  .custom-domain-message > span {
    display: block;
    width: 100%;
    text-align: center; }
  .custom-domain-message.error-in-panel {
    max-width: 324px; }

.custom-domain-validation-message {
  position: relative;
  padding: 0 15px;
  margin: 1rem 0;
  padding-bottom: 20px;
  display: block; }

.domain-setup-success-msg {
  color: #2e7d32;
  padding: 1rem; }
