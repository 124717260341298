.mobile-app-preview {
  display: block;
  margin: 24px auto;
  height: 600px;
  width: auto; }
  .mobile-app-preview > .iframe-wrapper {
    position: relative;
    width: 100%;
    background: #ffffff;
    display: block;
    -webkit-overflow-scrolling: touch;
    transform: scale(0.9);
    transform-origin: top center; }
    .mobile-app-preview > .iframe-wrapper .mobile-toolbar {
      width: 343px;
      height: 32px;
      position: absolute;
      left: 50%;
      top: 60px;
      margin-left: -170px;
      z-index: 2; }
    .mobile-app-preview > .iframe-wrapper #iframe {
      width: 330px;
      height: 685px;
      position: absolute;
      left: 50%;
      top: 90px;
      margin-left: -165px;
      z-index: 1;
      border-bottom-right-radius: 15px; }

.pgmol-mobile-app-preview {
  transform: scale(0.8);
  margin-top: -50px; }
  .pgmol-mobile-app-preview > .iframe-wrapper .mobile-toolbar {
    width: 415px;
    height: 63px;
    top: 38px;
    margin-left: -208px; }
  .pgmol-mobile-app-preview > .iframe-wrapper #iframe {
    width: 410px;
    height: 824px;
    top: 100px;
    margin-left: -206px;
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px; }

#iframe::-webkit-scrollbar {
  display: none; }

#splash {
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  background-position-x: center;
  position: absolute;
  right: 0;
  top: 90px;
  transition: opacity 0.4s ease;
  z-index: 0;
  width: 330px;
  height: 685px;
  margin-left: -165px;
  z-index: 1; }

#desktop-splash {
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  bottom: 0;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  left: 50%;
  position: absolute;
  right: 0;
  top: 50px;
  transition: opacity 0.4s ease;
  z-index: 0;
  width: 748px;
  height: 480px;
  margin-left: -370px;
  z-index: 1; }

#ipad-splash {
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  bottom: 0;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  left: 51%;
  position: absolute;
  right: 0;
  top: 50px;
  transition: opacity 0.4s ease;
  z-index: 0;
  width: 391px;
  height: 535px;
  margin-left: -200px;
  z-index: 1; }

#splash > .logo {
  width: 96px;
  border-radius: 3px; }

.loaded #splash {
  display: none; }

@media (max-height: 772px) {
  .flex-container {
    height: 100%; } }

.ipad-app-preview {
  width: 100%;
  height: 691px;
  margin: 15px 0; }
  .ipad-app-preview .iframe-wrapper-ipad {
    height: 100%;
    background-color: white;
    padding-top: 20px; }
    .ipad-app-preview .iframe-wrapper-ipad #iframe {
      width: 386px;
      height: 535px;
      position: absolute;
      left: 50%;
      top: 51px;
      margin-left: -197px;
      z-index: 1; }

.desktop-app-preview {
  width: 100%;
  height: 95%;
  max-width: 1366px;
  max-height: 768px;
  border-radius: 20px;
  padding: 10px;
  background: #000;
  margin: auto 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative; }
  .desktop-app-preview .iframe-wrapper {
    height: 100%;
    width: 100%;
    padding: 0px;
    position: relative;
    border-radius: 20px;
    background: white;
    overflow: hidden; }
    .desktop-app-preview .iframe-wrapper #iframe {
      position: absolute;
      z-index: 1;
      margin: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1) translateZ(0);
      width: 1366px;
      height: 768px;
      transform-style: preserve-3d;
      border-radius: 20px;
      backface-visibility: hidden;
      -webkit-font-smoothing: antialiased;
      transform-style: preserve-3d;
      will-change: transform; }
  .desktop-app-preview .hide-wix-preview {
    background-color: whitesmoke;
    width: 100%;
    height: 100%;
    padding: 36px;
    font-size: 18pt;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .desktop-app-preview .hide-wix-preview button {
      vertical-align: bottom;
      font-size: 0.65em;
      letter-spacing: 0px; }

.mobile-frame {
  width: 450px;
  height: auto;
  position: absolute;
  top: 5px;
  left: 50%;
  margin-left: -225px;
  pointer-events: none; }

.ipad-frame {
  width: 450px;
  height: auto;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -225px;
  pointer-events: none;
  z-index: 3; }
