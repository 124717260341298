.offer-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.offer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 20px;
  height: 650px; }

@media screen and (min-width: 971px) {
  .offer-overlay {
    height: calc(100% - 68px); } }

@media screen and (min-width: 1250px) {
  .offer-content {
    width: 70%; } }

@media screen and (max-width: 970px) {
  .offer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 100%; } }

.offer-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 414px;
  width: 320px; }
  .offer-details-container div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .offer-details-container div {
    padding: 15px 0; }
  .offer-details-container h1,
  .offer-details-container p {
    margin: 0; }
  .offer-details-container p {
    font-size: 140%; }

.diagonal-line {
  width: 190px;
  height: 25px;
  border-bottom: 4px solid red;
  transform: translateY(0px) translateX(-7px) rotate(-15deg);
  -webkit-transform: translateY(0kpx) translateX(-7px) rotate(-15deg);
  position: absolute; }

.price-display-bold {
  font-weight: 900;
  font-size: 300%; }

.price-display {
  font-weight: 400; }

.invalid-offer-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.invalid-offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 150px; }
  .invalid-offer span {
    margin-bottom: 20px; }

.offer-terms-conditions {
  padding: 0 10px;
  text-align: center; }
