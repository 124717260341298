.modal-details-actions {
  display: flex; }
  .modal-details-actions button {
    padding: 0 !important; }
    .modal-details-actions button svg {
      margin: 0 5px;
      padding: 5px; }
      .modal-details-actions button svg:first-child {
        margin-left: 0 !important; }
      .modal-details-actions button svg:last-child {
        margin-right: 0 !important; }
