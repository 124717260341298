.billing:not(.thing) {
  max-height: calc(100vh - 211px);
  overflow-y: auto; }
  .billing:not(.thing) > div {
    float: left;
    padding: 0 12px; }
  .billing:not(.thing) .card {
    width: 50%; }
  .billing:not(.thing) .appsumo-invoice {
    width: 100%; }

.thing .plan-details {
  display: flex;
  flex-direction: column;
  align-items: center; }

.header {
  padding: 12px 0;
  margin: 24px 0;
  border-bottom: 1px solid #e2e2e2; }
