.beezer-mobile-text-field-container {
  display: flex;
  align-items: baseline;
  width: 324px; }

.beezer-mobile-extension-selector {
  margin-left: auto;
  width: 100px;
  height: 30px; }

.uk-flag-logo {
  position: relative;
  height: 20px;
  top: 4px;
  z-index: 2;
  left: 3px; }

.mobile-extension-label {
  position: relative;
  z-index: 3; }

.rectangle {
  position: relative;
  width: 75px;
  height: 30px;
  background: #f2f2f2;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center; }

.circle {
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px; }
