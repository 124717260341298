.icon {
  position: relative;
  display: inline-block;
  color: var(--primaryColor);
  fill: currentcolor;
  height: 32px;
  width: 32px; }

.icon.contacts,
.icon.share-via-sms,
.icon.share-via-email,
.icon.social-media,
.icon.history-icon,
.icon.qr,
.icon.widget {
  color: var(--primaryColor);
  height: 32px;
  width: 32px; }

.icon.undo {
  height: 18px;
  width: 18px; }

.icon.delete {
  bottom: 1px;
  height: 17px;
  width: 17px; }

.icon.undo {
  right: 6px; }

.icon.dashboard,
.icon.distribute,
.icon.builder,
.icon.subscribe {
  height: 32px;
  width: 32px; }

.icon.builder {
  position: relative;
  left: 3px; }

.icon.drag {
  height: 17px;
  width: 17px; }

.icon.view,
.icon.tabview,
.icon.homepage {
  color: rgba(65, 65, 65, 0.75);
  bottom: 1px;
  height: 15px;
  width: 15px; }

.icon.pencil {
  height: 34px;
  width: 34px;
  right: 6px; }

.icon.create,
.icon.close {
  height: 20px;
  width: 20px; }

.icon.back {
  height: 19px;
  width: 19px; }
