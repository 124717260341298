.ml-upgrade-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 100%;
  padding: 18px 0 0 0;
  text-align: center; }
  .ml-upgrade-dialog-content img {
    width: 100px;
    height: 100px; }

.custom-dialog {
  height: 270px !important; }
  .custom-dialog h3 {
    font-weight: 500;
    font-size: 24px; }
  .custom-dialog p {
    font-size: 17px; }
