.preferences {
  margin: 24px 0;
  padding: 0 12px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 60px; }
  .preferences h5 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase; }
  .preferences .header-section h3 {
    padding: 12px 0;
    border-bottom: 1px solid #e2e2e2;
    font-weight: 700;
    font-size: 20px; }
  .preferences .header-section p {
    font-size: 16px;
    font-weight: 600; }
    .preferences .header-section p:last-of-type {
      line-height: 0; }
  .preferences .header-section span {
    font-size: 13px; }
  .preferences .preferences-section .preference-logo {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0 24px; }
    @media (min-width: 481px) and (max-width: 767px) {
      .preferences .preferences-section .preference-logo {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
  @media (min-width: 481px) and (max-width: 992px) {
    .preferences {
      grid-template-columns: repeat(1, minmax(0, 1fr)); } }
