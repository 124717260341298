.navigation {
  display: flex;
  align-items: center;
  color: black;
  justify-content: space-evenly;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .navigation button {
    padding: 0 !important; }
    .navigation button svg {
      opacity: 0.8; }
  .navigation .navigation-text {
    display: flex;
    text-transform: uppercase;
    font-size: 12px; }
    .navigation .navigation-text span {
      font-family: 'Montserrat', sans-serif;
      margin: 0 3px; }
    .navigation .navigation-text .current-response,
    .navigation .navigation-text .total-responses {
      font-weight: 500; }
