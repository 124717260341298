.search-input-wrapper {
  position: relative;
  margin: 24px 42px; }
  .search-input-wrapper .search {
    outline: none;
    border-radius: 15px;
    padding: 6px 40px;
    color: #484848;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    border: 1px solid #d4d4d4; }
  .search-input-wrapper .search-icon {
    position: absolute;
    left: 10px;
    top: 6px; }
  .search-input-wrapper .filter-button {
    position: absolute;
    right: 0px;
    top: -6px; }
  .search-input-wrapper .filter-button-with-clear {
    position: absolute;
    right: 24px;
    top: -6px; }
  .search-input-wrapper .clear-button {
    position: absolute;
    right: 0;
    top: -6px; }
  .search-input-wrapper .filtered::before {
    content: '\A';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primaryColor);
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 12px; }

.row .search-input-wrapper {
  margin: 24px 45px;
  top: 10px; }
  .row .search-input-wrapper .search {
    font-size: 16px; }

.top-0 {
  top: 0 !important; }
