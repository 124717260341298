.vote-table-container {
  height: calc(100% - 168px); }
  .vote-table-container .table .table-header > .th {
    text-align: left !important; }
  .vote-table-container .table .table-header > .th.created-at {
    justify-content: left; }
    .vote-table-container .table .table-header > .th.created-at button {
      position: unset; }
  .vote-table-container .table .table-body .table-row > .td {
    justify-content: left; }
  .vote-table-container h5 {
    text-align: center; }
  .vote-table-container .table-buttons {
    justify-content: center;
    display: flex; }
    .vote-table-container .table-buttons .check-all {
      display: flex;
      align-items: center;
      margin-left: 24px; }
  .vote-table-container .th, .vote-table-container .td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .vote-table-container .td .row-actions svg {
    opacity: 0.7;
    cursor: not-allowed; }
  .vote-table-container .td.selected {
    display: flex;
    justify-content: center; }
  .vote-table-container .td.favourite svg {
    color: #707070 !important;
    opacity: 0.8; }
  .vote-table-container .end-xs {
    justify-content: space-evenly; }
