.feedback-container {
  display: flex;
  flex-direction: column;
  place-items: center;
  height: fit-content;
  text-align: center;
  row-gap: 1rem; }

.feedback-videos {
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; }

.feedback-description {
  width: 80%; }
  .feedback-description > .title {
    font-size: 2rem;
    font-weight: bold; }
  .feedback-description > p {
    font-size: 1.2rem; }

.feedback-text {
  width: 60%; }

.feedback-title {
  font-size: 2.5rem;
  font-weight: bold; }
