.updatePassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 20%; }

.notification {
  text-align: center;
  padding: 16px 10px;
  font-size: 15px;
  color: #fff;
  margin: 0 6px;
  border-radius: 8px;
  font-weight: 500; }
  .notification a {
    text-decoration: underline;
    color: #ffffff;
    font-weight: 600; }

.notificationSuccess {
  background-color: #589a5a; }

.notificationError {
  background-color: #f44336; }

.updatePassword-header {
  margin: 0 auto;
  text-align: center;
  width: 50%;
  max-width: 500px; }
  .updatePassword-header p {
    font-size: 30px;
    color: #1e2023; }
  @media screen and (max-width: 480px) {
    .updatePassword-header {
      width: 100%; } }
