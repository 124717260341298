.card-chip {
  width: 120px;
  margin: 26px auto;
  line-height: 24px;
  font-size: 13px;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 20px;
  font-weight: 400;
  border: 1px solid #555;
  transition: background-color 450ms ease-out; }

.selectable-card:hover .card-chip,
.selectable-card:focus .card-chip {
  background-color: var(--primaryColor);
  color: #ffffff; }

.selectable-card.disabled .card-chip,
.selectable-card.disabled:hover .card-chip,
.selectable-card.disabled:focus .card-chip {
  background-color: rgba(0, 0, 0, 0.1); }

.selectable-card:hover .no-highlight-on-hover,
.selectable-card:focus .no-highlight-on-hover {
  background-color: transparent; }

.no-border {
  border: 0;
  margin: 27px auto; }

.bold-label {
  font-weight: 700; }
