.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7c12f;
  text-align: center;
  word-wrap: normal;
  white-space: normal;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  width: auto;
  min-height: 30px;
  padding: 10px;
  border-radius: 4px;
  margin: 6px 0; }

.badge.launch {
  background-color: #00ff52; }

.badge.accelerate {
  background-color: #00ff52; }

.badge.expert {
  background-color: #00ff52; }

.badge.expired {
  color: #fff;
  background-color: #e0363f; }
