.apps-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-width: 1000px;
  width: 80%; }

.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.app-container {
  width: 100%;
  height: fit-content;
  box-shadow: 0px 2px 6px lightgrey;
  border-radius: 15px;
  display: flex;
  position: relative;
  padding: 1rem; }
  .app-container .app-details {
    padding: 16px;
    height: 40%;
    width: 60%;
    display: flex;
    flex-direction: column;
    row-gap: 10px; }
  .app-container .field {
    word-break: break-word; }
    .app-container .field .title {
      font-weight: bold; }
  .app-container .icon-container {
    width: 25%;
    max-height: 200px;
    overflow: hidden;
    border-radius: 15px; }
    .app-container .icon-container > img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .app-container .url > a {
    color: revert;
    word-break: break-word; }
  .app-container .action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    width: 15%; }
    .app-container .action-container .view-app-btn {
      height: 35px;
      width: 120px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      background-color: var(--primaryColor); }
  .app-container .app-actions {
    display: flex; }
    .app-container .app-actions button {
      text-transform: none; }
    .app-container .app-actions .duplicate-btn {
      color: var(--primaryColor); }
    .app-container .app-actions .delete-btn {
      color: #d32f2f; }

.section-title {
  text-align: center;
  font-size: 36px;
  margin: 1rem; }

.loading-indicator {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  align-items: center; }

.current-app {
  border: 2px solid var(--primaryColor); }

.apps-search-bar {
  width: 60%;
  max-width: 600px; }
