.header-counter-container {
  display: flex;
  text-align: right;
  flex-direction: column;
  font-family: 'Raleway', sans-serif;
  color: #444; }
  .header-counter-container .header-counter {
    margin: 0;
    font-size: 24px;
    font-weight: 800; }
    .header-counter-container .header-counter.numeric {
      font-size: 36px; }
  .header-counter-container .header-counter-title {
    margin: 0;
    font-weight: 300; }
