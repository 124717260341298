.ml-switch-label {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #454545; }

.ml-switch-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90px;
  width: 100%;
  padding: 24px 36px;
  background-color: #ffffff;
  transition: background-color 300ms ease-in; }

.ml-switch-container.enabled {
  background-color: #eaeeff; }
