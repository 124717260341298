.section_header {
  margin-bottom: 20px;
  width: 100%;
}

.select_container {
  width: 90%;
}

.select {
  margin: 10px 0;
}
