.container {
	height: 500px;
	margin: 15px 0;

} 

.auth-panel {
  background-color: #fff;
  border-radius: 6px;
  overflow-y: auto;
  width: 100%;
  padding: 20px;
}

.auth-panel-title {
  border-bottom: 2px solid #c8c8c8;
  width: 100%;
  padding-bottom: 15px;
}