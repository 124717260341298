.button {
  border-radius: 5px;
  font-weight: 700;
  color: #ffbddb;
  padding: 10px;
  background-color: #261ed5;
  transition: all 0.4s;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 0px 20px #ffbddb;
}
