.selectable-card-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 90%;
  padding: 24px 36px;
  margin: 12px auto;
  justify-content: center;
  align-items: center; }

.selectable-card-container {
  margin-top: 24px;
  display: flex;
  flex-direction: row; }
  @media only screen and (max-width: 940px) {
    .selectable-card-container {
      flex-direction: column; } }

.selectable-card-menu-title {
  text-align: center;
  font-size: 28px;
  font-weight: 200;
  text-decoration: none;
  margin: 12px 0; }

.selectable-card-menu-subtitle {
  text-align: center;
  font-size: 12px; }

.selectable-card-menu-image-container {
  width: 100%; }

.selectable-card-menu-image-container > img {
  display: block;
  margin: 0 auto;
  width: 200px; }
