.main-menu-item {
  height: 90px; }
  .main-menu-item svg {
    color: #ffffff; }

.main-menu-item > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 109px;
  height: 100%;
  width: 100%; }

.main-menu-item.border-top > div {
  border-top: 1px solid #d4d4d4; }

.main-menu-item-title {
  margin-top: 6px;
  margin-bottom: -6px;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center; }

.main-menu-item.active > div {
  background: #ffffff;
  border-right: 2px solid var(--primaryColor); }
  .main-menu-item.active > div .main-menu-item-title {
    color: var(--primaryColor); }
  .main-menu-item.active > div svg {
    color: var(--primaryColor); }

.pgmol-menu-item {
  width: 137px !important; }

.text-free-plan {
  margin-bottom: 9%;
  line-height: 160%;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
  font-weight: bold; }

.text-premium-plan {
  margin-top: 14%;
  margin-bottom: 12%;
  line-height: 160%;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff; }

.main-menu {
  background-color: var(--primaryColor);
  height: 100vh;
  overflow-x: hidden; }

.pgmol-main-menu {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }

.pgmol-main-menu::-webkit-scrollbar {
  display: none; }

.main-menu-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 109px;
  height: 100%;
  width: 101%; }

.main-menu-content,
.upgrade-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10%;
  margin-top: 10%; }
  .main-menu-content .upgrade-item-content,
  .upgrade-item .upgrade-item-content {
    display: flex;
    flex-direction: column;
    align-items: center; }

.line {
  width: 99%;
  height: 47px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(199, 199, 199, 0.911); }

@media (min-width: 1101px) {
  .main-menu-item {
    width: 142px; } }

@media (max-height: 740px) {
  .main-menu-item {
    height: 80px; } }
