.beezer-modal > div {
  max-height: 640px;
  max-width: 770px;
  margin: 0 auto;
  display: flex;
  flex-flow: column; }

.adillo_img_wrapper {
  height: 35% !important; }

.adillo_message {
  height: 25%; }
