.button {
  background-color: red;
  width: 90%;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  padding: 10px;
  transition: all 0.4s;
  margin: auto;
}

.button:hover {
  transform: scale(1.1);
}
