.visit-link-mobile {
  display: none; }

.mobile-text {
  display: none; }

@media only screen and (max-width: 600px) {
  .error-illustration {
    display: none; }
  .visit-link-desktop {
    display: none; }
  .visit-link-mobile {
    display: block; }
  .desktop-text {
    display: none; }
  .mobile-text {
    display: inline; } }
