.app-icon-container {
  height: 60px;
  width: 60px;
  margin: 12px;
  border-radius: 5px;
  background-color: #21a6b0;
  overflow: hidden; }

.app-icon-container > img {
  height: 100%;
  width: 100%; }

.plans-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 211px); }
  .plans-container .plan-name {
    text-transform: capitalize; }
  .plans-container .controls {
    min-width: 48px; }

.subscription {
  justify-content: space-around !important; }
