.pn-category:before {
  content: '';
  width: 12px;
  height: 12px;
  display: inline-block; }

.pn-category.all:before {
  background-color: #6dcff6; }

.instructions-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 1em;
  border-radius: 10px;
  border: 1px solid lightgrey;
  background-color: #ffffff;
  width: fit-content;
  color: var(--primaryColor); }
  .instructions-container .instructions-section-1,
  .instructions-container .instructions-section-2 {
    font-weight: bold; }
    .instructions-container .instructions-section-1 a,
    .instructions-container .instructions-section-2 a {
      text-decoration: underline; }
