@import '../../../../../../variables';
.imageContainer {
  width: 100%;
  height: 100%;

  border-radius: 15px;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
}

.forwardButton {
  top: 50%;
  position: absolute !important;
  right: 16px;
}
.backButton {
  top: 50%;
  position: absolute !important;
  left: 16px;
}

.iconButton {
  border-radius: 50%;
  background: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  color: $primary-color;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 15px darkgray;
  }
}
.container {
  width: 100%;
  height: 100%;
  position: relative;
}
