.add-admin-form {
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  width: 100%;
  min-width: 500px; }
  .add-admin-form .select-app-field {
    padding: 0px 6px;
    margin: 1rem 0; }
  .add-admin-form h5 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    text-align: center; }
  .add-admin-form .input-info {
    display: block;
    font-size: 11px;
    top: -10px;
    margin-top: -8px;
    text-align: right;
    margin-bottom: 15px; }

.profile-fieldset > div {
  padding: 0 12px; }

.contact-counter {
  margin: 20px;
  color: #010101; }

.counter-number {
  color: #010101;
  margin: 5px;
  font-size: 35px; }

.upload-container {
  margin: 10px; }

.dropzone {
  width: 350px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding: 7px;
  background-color: #f4f4f4;
  text-align: center; }

.dropzone-text {
  color: #010101; }

.dropzone-icon {
  margin: 5px;
  color: #c1c1c1; }

.dropzone-title {
  font-size: 30px;
  text-align: center;
  color: #c1c1c1; }

.info-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .info-box .instruction-box {
    text-align: left;
    width: 60%;
    border: 1px solid lightgray;
    padding: 0px 12px;
    margin: 1rem 0;
    border-radius: 8px; }
