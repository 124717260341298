@import '../../../variables';

.table {
  position: relative;
  height: inherit;
  width: 100%;
  min-width: 620px;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  width: 100%;
  //border-collapse: separate;
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 15px;
  font-size: 16px;

  .table-header {
    height: 77px;
    background-color: #eaeeff;
    margin: 0;
    border-bottom: 1px solid #cccccc;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 0 7px 0px 15px;

    .clickable {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #createdAt {
      display: flex !important;
    }

    #status {
      display: flex !important;
    }

    > .th {
      font-family: 'Raleway', sans-serif;
      font-weight: bold;
      line-height: 16px;
      letter-spacing: 0.01071em;
      display: table-cell;
      vertical-align: inherit;
      text-align: center;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;

      span {
        font-family: 'Raleway', sans-serif;
        color: #000000 !important;
      }

      svg {
        fill: $primary-color !important;
      }

      &.created-at {
        display: flex;
        justify-content: center;
        position: relative;
        button {
          position: absolute;
          right: calc(50% - (103.85px / 2) - 24px);
        }
      }

      span.create-at {
        display: inline-block;
        height: 100%;
      }

      &:last-child {
        border-right: none;
      }

      .action-menu-container {
        position: absolute;
        left: 36px;
        top: calc(50% - 12px);
        width: 24px;
        z-index: 10;
      }
    }
  }

  .table-body {
    display: block;
    height: 99%;
    overflow-y: auto;

    .table-row {
      background-color: #fff;
      border-radius: 3px;
      min-height: 80px;
      height: 100%;
      padding: 0 7px 0px 20px;
      align-items: center;

      &:hover {
        background-color: #f1f1f1;
      }

      &.touchable {
        cursor: pointer;
        border-radius: 6px;
        transition: all 300ms ease-in-out;
      }

      > .td {
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #484848;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-family: 'Raleway', serif;

        div,
        span {
          font-family: 'Raleway', serif;
        }

        &.selected > div > div > div {
          margin-left: 5px !important;

          svg {
            fill: $primary-color !important;
          }
        }

        .action-menu-container {
          position: absolute;
          left: 35px;
          top: calc(50% - 12px);
          width: 24px;
          z-index: 1000;

          button {
            width: 24px !important;
            height: 24px !important;
            padding: 0 !important;

            svg {
              fill: $primary-color !important;
            }
          }
        }

        &.fix-left {
          svg {
            fill: $primary-color !important;
          }
          .action-menu-container {
            left: 30px;
          }
        }
      }
    }

    .name {
      color: #333;
      font-size: 13px;
      font-weight: 600;
      line-height: 48px;
    }
  }
}

.tags {
  overflow: hidden;
}

.table-wrapper {
  flex: 0.4 1;
  margin-bottom: 4rem;
  height: 40vh;
}
.sms-engage .table {
  margin-bottom: 60px;
}
