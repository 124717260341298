.modal-detail-paper {
  border-radius: 5px !important; }
  .modal-detail-paper div:first-child {
    border-radius: 5px !important; }

.modal-detail-content {
  width: 50% !important;
  min-width: 500px;
  padding-top: 0 !important;
  transform: none !important; }
  .modal-detail-content .modal-details-content {
    min-height: 300px;
    margin: 12px; }
    .modal-detail-content .modal-details-content .modal-heading-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px; }
      .modal-detail-content .modal-details-content .modal-heading-container button {
        padding: 0 !important;
        margin: 0 !important;
        width: auto !important;
        height: auto !important; }
      .modal-detail-content .modal-details-content .modal-heading-container h5 {
        font-weight: 200;
        font-size: 26px;
        text-align: left;
        display: inline;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    .modal-detail-content .modal-details-content hr {
      background-color: #959595; }
      .modal-detail-content .modal-details-content hr:first-child {
        height: 2px; }
    .modal-detail-content .modal-details-content .inner-container {
      margin: 0 15px; }
      .modal-detail-content .modal-details-content .inner-container .icons {
        display: flex;
        justify-content: space-between; }
        .modal-detail-content .modal-details-content .inner-container .icons svg {
          color: lightgray;
          height: 30px !important;
          width: 30px !important;
          border-radius: 25px;
          border: 1px solid grey; }
          .modal-detail-content .modal-details-content .inner-container .icons svg:hover {
            cursor: pointer; }
          .modal-detail-content .modal-details-content .inner-container .icons svg.disabled {
            cursor: not-allowed;
            opacity: 0.5 !important; }
          .modal-detail-content .modal-details-content .inner-container .icons svg.favourite path {
            fill: black; }
    .modal-detail-content .modal-details-content .date {
      display: block; }
    .modal-detail-content .modal-details-content .label {
      color: var(--primaryColor);
      display: block;
      margin: 15px 0 10px;
      font-size: 14px; }
    .modal-detail-content .modal-details-content .value {
      color: black;
      font-size: 14px;
      overflow: auto;
      max-height: 50px;
      display: inline-block; }
    .modal-detail-content .modal-details-content .fields {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-height: 300px;
      overflow: auto; }
      .modal-detail-content .modal-details-content .fields .field {
        width: 50%; }
        .modal-detail-content .modal-details-content .fields .field:nth-child(odd) {
          padding-right: 15px; }
        .modal-detail-content .modal-details-content .fields .field:nth-child(even) {
          padding-left: 15px; }
