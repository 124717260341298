.pgmol-modal-main-container .text-field-wrapper {
  padding-left: 0px; }

.pl-table-text-left {
  text-align: justify !important;
  justify-content: flex-start !important; }

#hotel_phone::-webkit-inner-spin-button,
#hotel_phone::-webkit-outer-spin-button,
#therapist_phone::-webkit-inner-spin-button,
#therapist_phone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
