.analytics-action {
  margin: 10px 0 30px 0; }

.counters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.chart {
  flex: 2;
  margin: 24px 12px 24px 24px; }

.chart-header {
  font-size: 13px;
  font-weight: 400;
  margin: 12px 0; }

.line-chart-free-plan {
  opacity: 0.5; }

.modal-upgrade-container {
  display: flex;
  justify-content: center; }

.modal-upgrade {
  z-index: 2;
  position: absolute;
  background-color: #fff;
  width: 380px;
  height: 240px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 9px 31px 6px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 0px 9px 31px 6px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 9px 31px 6px rgba(0, 0, 0, 0.48); }

.modal-upgrade-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10%;
  margin-top: 14%; }

.modal-upgrade-text {
  margin-bottom: 12%;
  line-height: 160%;
  text-align: center;
  vertical-align: middle; }

.button-upgrade {
  background-color: #a5be3e;
  border: none;
  color: white;
  width: 110px;
  height: 35px;
  margin-top: 8%;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px; }

.button-upgrade:hover {
  background-color: #95ab39; }

.date-modal {
  display: grid;
  place-items: center;
  margin-top: -20vh; }
