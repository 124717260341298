.text-free-plan {
  margin-bottom: 9%;
  line-height: 160%;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
  font-weight: bold; }

.text-premium-plan {
  margin-top: 14%;
  margin-bottom: 12%;
  line-height: 160%;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
  font-weight: bold; }

.upgrade-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10%;
  margin-top: 10%; }
