.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border-right: 1px solid #d4d4d4;
  overflow: visible;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04), 0 3px 10px 0 rgba(0, 0, 0, 0.08); }

.sidebarNote {
  padding: 15px;
  color: #261ed5; }

.sidebarNote a {
  text-decoration: underline;
  color: #261ed5; }

.sidebar.sidebar-small.animate {
  animation: 200ms linear smallSlideIn; }

.sidebar.sidebar-large.animate {
  animation: 300ms linear largeSlideIn; }

@keyframes smallSlideIn {
  0% {
    transform: translate3d(-200px, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes largeSlideIn {
  0% {
    transform: translate3d(-380px, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.flex-wrapper {
  position: relative;
  flex: 1;
  overflow: hidden; }
  .flex-wrapper .test-btn {
    margin: 32.5px auto; }

.sidebar-small {
  width: 170px;
  z-index: 501;
  background-color: #fafafa; }

.pgmol-sidebar .sidebar-small {
  width: 248px !important; }

.sidebar-large {
  width: 400px;
  position: absolute;
  top: 0;
  left: 170px;
  z-index: 500;
  background-color: #f0f0f0; }

.sidebar-full {
  width: calc(100% - 260px);
  padding: 10px 1vw; }
  .sidebar-full > div > div {
    margin-top: 10px; }

@media (min-width: 1101px) {
  .sidebar-large {
    left: 267px; } }

.sidebar .loading {
  display: flex;
  align-items: center;
  justify-content: center; }

.scroll-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden !important;
  overflow-y: auto; }

.scroll-section {
  width: 100%;
  flex: 1; }

.sidebar-large
.scroll-section
> div:not([class])
> div:not([class]):first-child {
  width: 400px !important;
  z-index: 999; }

.scroll-section.centered {
  text-align: center; }

.menu-item {
  display: block;
  width: 100%; }

.menu-item > div {
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 16px; }

.menu-item.active > div {
  background: #eaeeff;
  display: flex;
  justify-content: space-between; }

.menu-item.active.build-pages-item > div {
  background: #eaeeff; }

.menu-item.disabled > div {
  opacity: 0.5;
  pointer-events: none; }

.menu-item-label {
  flex: 1;
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  color: var(--primaryColor); }

.menu-item-icon-container {
  width: 36px; }
  .menu-item-icon-container svg {
    color: var(--primaryColor); }

.menu-item-left-action {
  margin-left: -24px; }

.menu-item-right-action {
  margin-right: -24px; }

/* sidebar-extended classes are applied to the main content section */
.sidebar-extended {
  padding-left: 170px;
  height: 100%; }

.sidebar-fully-extended {
  padding-left: 560px;
  transition: margin 300ms linear;
  height: 100%; }

@media (min-width: 1101px) {
  .sidebar-small {
    width: 267px; }
  .menu-item > div {
    padding: 24px 36px; }
  /* sidebar-extended classes are applied to the main content section */
  .sidebar-extended.sidebar-extended {
    padding-left: 267px; }
  .sidebar-fully-extended.sidebar-fully-extended {
    padding-left: 657px;
    transition: margin 300ms linear; } }
