.text-area-wrapper {
  font-size: 13px;
  margin: 36px 0;
  padding: 0 6px;
  width: 324px; }

textarea {
  padding: 6px;
  margin-top: 8px;
  width: 100%;
  max-width: 100%;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  line-height: 21px;
  -webkit-appearance: none;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  resize: vertical; }

textarea:focus {
  outline: none; }

.text-area-label {
  color: rgba(0, 0, 0, 0.298039);
  line-height: 16px;
  font-size: 12px; }

textarea::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff; }

textarea::-webkit-scrollbar-thumb {
  background-color: #ececec; }

textarea::-webkit-scrollbar-track {
  background-color: #f6f6f6;
  outline: none; }

.pgmol-label-style {
  font-size: 0.95rem !important;
  color: black !important; }

.required-asterik {
  display: flex; }

.required-asterik::after {
  content: ' *';
  color: red;
  font-size: 25px;
  display: inline; }

.textarea-container {
  position: relative;
  margin-top: 2vh; }
  .textarea-container textarea {
    margin: 0 !important; }
    .textarea-container textarea:focus + p {
      transform: scale(0.8) translate(-5px, -28px);
      transform-origin: left top; }
    .textarea-container textarea:not(:empty) + p {
      transform: scale(0.8) translate(-5px, -28px);
      transform-origin: left top; }
  .textarea-container > p {
    color: black;
    font-size: 0.95rem !important;
    position: absolute;
    left: 5px;
    top: -20px;
    z-index: 99;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform: scale(1) translate(0px, 0px); }

.required-label::after {
  content: '*';
  color: red;
  font-size: 25px;
  display: inline; }

.no-label::after {
  content: '*';
  color: transparent;
  font-size: 25px;
  display: inline; }
