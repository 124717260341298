.text-field-wrapper {
  position: relative;
  overflow: hidden;
  width: 324px;
  max-width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 0.5rem;
  margin-bottom: 0rem !important;
  margin-left: auto;
  margin-right: auto; }
  .text-field-wrapper .overflow-disable {
    overflow: visible !important; }

.input-action-group {
  position: relative;
  display: flex;
  flex: 1; }

.input-action {
  position: absolute;
  right: -6px;
  bottom: 7px; }

.right-input-action {
  display: flex;
  height: 48px;
  width: 48px;
  position: absolute;
  bottom: 2px;
  right: 0;
  justify-content: center;
  align-items: center; }

.required-asterik {
  display: flex; }

.pgmol-label-style {
  font-size: 0.95rem !important;
  color: black; }

.required-asterik::after {
  content: ' *';
  color: red;
  font-size: 25px;
  display: inline; }
