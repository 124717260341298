@import '../../../../variables.scss';
.scan_button {
  height: 35px;
  padding: 0 40px;
  font-size: 20px;
  font-weight: bold;
  color: $primary-text-color;
  background-color: $primary-color;
  border-radius: 20px;
}

.scan_button:hover {
  box-shadow: 0px 0px 20px $primary-color-light;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_text {
  font-family: 'Raleway', 'Karla', sans-serif;
  font-size: 0.82rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 0.2rem;
  color: #333;
}

b {
  margin-left: 0.6rem;
  font-size: 1rem;
}

.header_search {
  width: 40%;
}

.users_container {
  display: flex;
}

.button {
  width: 90%;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  padding: 10px;
  transition: all 0.4s;
  margin: auto;
}

.button:hover {
  transform: scale(1.1);
}

.user_type {
  display: flex;
  margin: 10px auto;
}

.user_type > div {
  padding: 12px 24px;
  cursor: pointer;
  background-color: $primary-color;
  color: $primary-text-color;
  margin: 0 5px;
  border-radius: 17px;
}

.user_type > div:hover {
  box-shadow: 0px 0px 20px $primary-color-light;
}

.user_type_text {
  margin: 10px auto;
  font-size: 1.8rem;
  font-weight: 700;
}
