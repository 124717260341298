.menuButton {
  color: var(--primaryColor);
  padding: 0;
  margin: 6; }

.editor-body {
  display: block;
  width: 100%; }

.beezer-modal > div {
  max-height: 640px;
  max-width: 770px;
  margin: 0 auto;
  display: flex;
  flex-flow: column; }

.beezer-medium-modal > div {
  max-height: 340px !important; }

.sidebar-header {
  padding: 0 12px;
  width: 100%;
  background-color: #ffffff;
  margin-top: 100px; }
  .sidebar-header .title div {
    display: flex;
    align-items: center; }

.sidebar-header .primary {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.sidebar-header .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }

.sidebar-header .primary .title * {
  color: var(--primaryColor);
  font-size: 24px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  margin: 0 0 0 6px;
  width: 100%; }

.sidebar-header .primary .pgmol-page-title * {
  font-size: 18px !important; }

.sidebar-header .primary .title .customise-menu-icon {
  display: flex !important;
  align-items: center;
  padding: 0px !important;
  margin-right: 4px !important; }

.sidebar-header .seperator {
  display: block;
  height: 38px;
  width: 1px;
  background-color: #d4d4d4; }

.sidebar-header .secondary {
  display: flex;
  height: 60px;
  align-items: center; }

.component-toolbar,
.item-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  margin-left: -12px;
  margin-right: -12px;
  color: #ffffff; }

.component-toolbar {
  background-color: var(--primaryColor); }
  .component-toolbar span {
    color: #ffffff; }

.item-toolbar {
  background-color: var(--primaryColor); }

.sidebar-header .component-toolbar .title,
.sidebar-header .item-toolbar .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  text-transform: none; }

.sidebar-header .component-toolbar .actions,
.sidebar-header .item-toolbar .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.sidebar-header .tertiary .split {
  display: inline-block;
  height: 32px;
  width: 1px;
  background-color: #f5f5f5; }

.preview-toggle {
  display: flex;
  align-items: center; }
  .preview-toggle span {
    padding: 0 25px; }

.justify-right {
  justify-content: flex-end;
  align-items: center; }

.switch {
  position: relative;
  width: 50px;
  height: 26px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.disabled:before {
  background: #bdbdbd !important; }

.slider:before {
  position: absolute;
  content: '';
  height: calc(50px - 50px* 3 / 5);
  width: calc(50px - 50px* 3 / 5);
  left: 7%;
  bottom: 7%;
  background-color: var(--primaryColor);
  -webkit-transition: 0.4s;
  transition: 0.4s; }

input:checked + .slider {
  background-color: transparent; }

input:checked + .slider:before {
  -webkit-transform: translateX(calc(50px* 9 / 20));
  -ms-transform: translateX(calc(50px* 9 / 20));
  transform: translateX(calc(50px* 9 / 20));
  background: var(--primaryColor); }

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
  border: 1px solid #1e2023; }

.slider.round:before {
  border-radius: 50%; }

.app-menu-container {
  display: flex; }

.all-apps-btn {
  height: 35px !important;
  padding: 0 20px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: #ffffff !important;
  background-color: var(--primaryColor) !important;
  white-space: nowrap; }

.suspension-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #d32f2f;
  color: #ffffff; }
  .suspension-banner a {
    color: #ffffff; }
  .suspension-banner p {
    margin: 10px; }
