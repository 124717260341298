.radio-button-group-splash {
  width: 100%;
  margin: 10px 0 0;
  justify-content: space-evenly;
  flex-flow: row;
  position: relative;
  display: flex; }
  .radio-button-group-splash div {
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap; }

.color-picker-container {
  width: 100%; }

.color-save-button-container {
  display: flex;
  justify-content: center; }
