@import '../../../../variables';

.orders_placed_card {
  text-align: center;
  background-color: #f5f9fb;
  box-shadow: 0 42px 64px 0 #e7e7ec;
  width: 80%;
  padding: 20px;
  margin: 30px auto;
}

.orders_placed_text {
  margin-top: 10px;
  text-align: left;
}

.button {
  border-radius: 5px;
  font-weight: 700;
  color: $primary-text-color;
  padding: 10px;
  background-color: $primary-color;
  transition: all 0.4s;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 0px 20px $primary-color-light;
}

.actionContainer {
  margin: 1rem 0;
}
