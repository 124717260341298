.notification-link-menu-wrapper {
  display: block;
  width: 100%; }
  .notification-link-menu-wrapper .error-message {
    font-size: 12px;
    line-height: 12px;
    color: #f44336;
    padding-left: 8px;
    position: relative; }
    .notification-link-menu-wrapper .error-message:before {
      content: '*';
      top: 0px;
      font-size: 10px;
      position: absolute;
      left: 0; }
  .notification-link-menu-wrapper .warning svg {
    fill: darkorange !important;
    color: darkorange !important;
    width: 12px !important; }
  .notification-link-menu-wrapper .notification-link-container {
    display: flex;
    align-items: stretch;
    justify-items: stretch;
    width: 324px;
    margin: 0 auto; }
    .notification-link-menu-wrapper .notification-link-container .link-radio-option-wrapper {
      display: inline-block; }
      .notification-link-menu-wrapper .notification-link-container .link-radio-option-wrapper.hasOptions .text-field-wrapper {
        max-width: 280px; }
      .notification-link-menu-wrapper .notification-link-container .link-radio-option-wrapper .text-field-wrapper {
        margin: 0 auto; }
      .notification-link-menu-wrapper .notification-link-container .link-radio-option-wrapper .select-field-wrapper {
        width: 100%;
        padding-left: 6px;
        padding-right: 6px;
        margin: 0; }
    .notification-link-menu-wrapper .notification-link-container .radio-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: space-around; }
      .notification-link-menu-wrapper .notification-link-container .radio-wrapper > div {
        top: 10px; }
