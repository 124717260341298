.tooltip {
  top: 7px;
  right: 18px;
}


.forumDisabledDialog
{
  div > div {
    width: 100%;
    max-width: 500px!important;
    margin: 0px auto;
    border: 1px solid #d4d4d4;
    border-radius: 20px;
  
  }

  div > div> div{
    border-radius: 20px!important;
    box-shadow: none!important;
    border: none!important;
    padding: 16px 11px 10px 11px !important
  }

  div + div{
    background-color: #ffffff6b!important;
  }

  h2{
    font-family: 'Raleway', 'Karla', sans-serif;
    font-size: 18px;
    padding: 0px!important;
    color: #000000;
  }

  button{
    border-radius: 30px!important;
  }
}