.types-panel {
  background-color: #fff;
  border-radius: 6px;
  overflow-y: auto;
  width: 100%;
  padding: 20px; }

.types-container {
  margin-top: 10px;
  height: 100%;
  overflow: scroll; }
  .types-container .select-field-wrapper {
    width: 100%;
    margin-top: 8px; }
  .types-container .small-toggle {
    margin-top: 47px;
    font-size: 13px;
    padding: 0px 21px 0 11px; }
  .types-container .delete-icon {
    margin-top: 34px !important; }
  .types-container .small-toggle-last {
    margin-top: 47px;
    font-size: 13px; }
  .types-container .small-toggle label {
    color: rgba(0, 0, 0, 0.65); }

.types-divider {
  height: 50px;
  display: flex;
  flex-direction: row; }

.types-divider-line {
  height: 50%;
  width: 40%;
  border-bottom: 2px solid #c8c8c8;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0; }

.types-panel-title {
  border-bottom: 2px solid #c8c8c8;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.types-panel-title h6 {
  font-weight: normal;
  font-size: 17px;
  margin: 0px; }

.types-panel-title button.download {
  padding: 10px 26px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: var(--primaryColor);
  border-radius: 20px; }

.types-panel-register {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.types-type {
  display: flex;
  justify-content: space-evenly;
  width: 100%; }

.types-type-button {
  flex-grow: 1;
  margin: 10px; }

.types-panel-privacy-url-textbox {
  width: 100% !important; }
