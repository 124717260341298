.capitilize {
  text-transform: capitalize; }

.actionButtons {
  display: flex; }

.bookingLoadingContainer {
  width: 80% !important;
  margin: 0 auto !important;
  position: fixed !important;
  top: 10% !important;
  right: 0% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  z-index: 999 !important; }
  .bookingLoadingContainer > div {
    height: 100%;
    width: 40%;
    margin: 0 auto;
    display: grid;
    place-items: center; }

.dateHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22;
  font-weight: 600;
  font-family: "Raleway", sans-serif; }

.success {
  color: #2e7d32; }

.error {
  color: #d32f2f; }

.notAvailable {
  color: #ffa500; }

.headerLabel {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 20px;
  position: relative;
  top: 6px;
  padding: 0 8px; }

.scrollHeader {
  display: flex;
  justify-content: space-between;
  align-items: center; }
