.pn-container {
  position: relative;
  margin: 12px auto;
  width: 100%;
  height: 800px; }
  .pn-container .mobile-image {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 440px;
    pointer-events: none; }
  .pn-container .desktop-image {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 440px;
    pointer-events: none; }
  .pn-container .mobile-background {
    position: absolute;
    z-index: 1;
    background: linear-gradient(171deg, #020024 6%, #090979 30%, #ff00c4 100%);
    width: 70%;
    top: 70px;
    left: 15%;
    height: 635px; }
  .pn-container .desktop-background {
    position: absolute;
    z-index: 1;
    background: linear-gradient(171deg, #020024 6%, #090979 30%, #ff00c4 100%);
    width: 92%;
    top: 17px;
    left: 4%;
    height: 280px; }
  .pn-container .mock-header {
    display: flex;
    justify-content: space-between;
    font-size: 1.35rem;
    align-items: center;
    min-height: 64px;
    width: 100%;
    background: #2d3440;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; }
    .pn-container .mock-header .mock-appname {
      color: #f8f0ee; }
  .pn-container .notification-component .title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.75;
    text-transform: uppercase;
    padding: 15px; }
  .pn-container .notification-component .notification {
    background: #fff;
    border: 1px solid #2d3440;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04), 0 3px 10px 0 rgba(0, 0, 0, 0.08);
    margin: 1px;
    padding: 15px;
    display: block;
    text-align: left;
    margin: 3px 6px;
    color: #000; }
    .pn-container .notification-component .notification .time {
      font-size: 0.8em;
      line-height: 20px;
      padding-bottom: 15px; }
    .pn-container .notification-component .notification .notification-title {
      font-size: 1em;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden; }
    .pn-container .notification-component .notification .notification-message {
      font-size: 0.9em;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 400;
      line-height: 1.5; }
    .pn-container .notification-component .notification .notification-image {
      width: 100%;
      margin-top: 10px; }

.mobile-toolbar {
  position: absolute;
  z-index: 2;
  top: 70px;
  left: 72px;
  width: 293px;
  height: 30px;
  background: #2d3440; }

.mobile-pn {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  z-index: 3;
  top: 95px;
  left: 72px;
  width: 293px;
  height: 601px;
  overflow: auto; }
  .mobile-pn .notification-component {
    width: 100%;
    font-size: 1em; }

.desktop-pn {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  z-index: 2;
  top: 19px;
  left: 20px;
  width: 400px;
  height: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto; }
  .desktop-pn .notification-component {
    width: 50%;
    font-size: 0.8em; }
