.create-group-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 700px;
  color: #333; }
  .create-group-form a {
    color: revert; }
  .create-group-form h5 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    text-align: center; }
  .create-group-form .input-info {
    display: block;
    font-size: 11px;
    top: -10px;
    margin-top: -8px;
    text-align: right;
    margin-bottom: 15px; }

.profile-fieldset > div {
  padding: 0 12px; }

.subscription-error-msg {
  color: #f44336; }

.subscription-link {
  text-align: center; }

.item-order {
  display: flex;
  justify-content: space-between;
  column-gap: 0.6rem;
  align-items: center;
  width: 324px;
  margin: 0 auto; }
