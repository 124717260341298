$mobile-breakpoint: 600px;
$tablet-breakpoint: 1100px;

$menu-width: 142px;
$menu-width-tablet: 109px;
$sidebar-small-width: 267px;
$sidebar-small-width-tablet: 170px;
$toggle-switch-width: 50px;
$toggle-switch-height: 26px;

$primary-color: var(--primaryColor);
$primary-color-light: #d6d6d8;
$primary-color-dark: #0000a2;
$primary-text-color: #ffffff;
$secondary-color-light: #ffffff;
$secondary-color: #eaeeff;
$secondary-text-color: #261ed5;
$accent-color: #8b24fc;
$main-black: #1e2023;
$main-grey: #e1dede;
$light-grey: #f0f0f0;
$main-white: #ffffff;
$disabled-color: #d7d7d7;
$info-color: #5bc0de;
$info-color-dark: #39accd;
$success-color: #2e7d32;
$error-color: #d32f2f;
$pending-color: #261ed5;
$default-font: 'Raleway', sans-serif;
