.plan-description {
  font-size: 12px; }

.relative {
  position: relative; }

.plan-image-container {
  width: 100%; }

.plan-image-container > img {
  display: block;
  margin: 0 auto;
  width: 120px; }

.offer-card-footer {
  display: flex;
  flex-direction: column; }

.overlay.select-plans {
  background-color: #e1dede; }
  .overlay.select-plans .select-plan-header {
    padding: 10px 0 20px 0; }
    .overlay.select-plans .select-plan-header svg {
      width: 70px;
      height: 70px; }
  .overlay.select-plans .plan-container {
    padding-top: 30px; }
    .overlay.select-plans .plan-container .plan-card-holder {
      width: 18%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .overlay.select-plans .plan-container .plan-card-holder .plan-card.active {
        border: 3px solid #d6d6d8; }
      .overlay.select-plans .plan-container .plan-card-holder .plan-card {
        padding: 35px 20px;
        margin: 20px;
        background: #fff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 80%;
        position: relative; }
        .overlay.select-plans .plan-container .plan-card-holder .plan-card .plan-title {
          font-size: 23px;
          font-weight: 800;
          font-style: italic;
          color: var(--primaryColor);
          text-align: center;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center; }
        .overlay.select-plans .plan-container .plan-card-holder .plan-card .plan-price {
          font-size: 35px;
          font-weight: bold; }
          .overlay.select-plans .plan-container .plan-card-holder .plan-card .plan-price .plan-format {
            padding-left: 5px;
            font-size: 18px; }
        .overlay.select-plans .plan-container .plan-card-holder .plan-card .plan-price-line-through {
          font-size: 25px;
          font-weight: lighter;
          text-decoration: line-through;
          display: flex;
          align-items: baseline; }
          .overlay.select-plans .plan-container .plan-card-holder .plan-card .plan-price-line-through .plan-format {
            padding-left: 5px;
            font-size: 18px; }
        .overlay.select-plans .plan-container .plan-card-holder .plan-card .plan-period {
          padding-top: 20px;
          font-size: 15px;
          color: #1e2023; }
        .overlay.select-plans .plan-container .plan-card-holder .plan-card .ongoing-price {
          padding-top: 5px;
          font-size: 13px;
          color: #1e2023; }
        .overlay.select-plans .plan-container .plan-card-holder .plan-card adds style .plan-subtext {
          padding: 0;
          position: absolute;
          top: -20px;
          left: 0;
          right: 0;
          font-size: 15px;
          color: #1e2023; }
        .overlay.select-plans .plan-container .plan-card-holder .plan-card .plan-description {
          text-align: center; }
        .overlay.select-plans .plan-container .plan-card-holder .plan-card .separator {
          margin: 20px 0;
          width: 35%;
          border: 1px solid #1e2023; }

.app-sumo-features {
  margin: 0;
  padding: 0;
  list-style: none !important;
  position: unset;
  background: none !important;
  position: relative; }
  .app-sumo-features .feature {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 0;
    list-style: none !important;
    position: unset;
    background: none !important;
    position: relative; }
    .app-sumo-features .feature .feature-check {
      border: none;
      float: left;
      margin: 0.15em 0.5em 0 0.5em; }
    .app-sumo-features .feature .feature-name {
      margin-left: 0.5em; }

.offer-terms-link {
  color: var(--primaryColor);
  font-size: 0.7em; }

.promo-offer-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0; }
  .promo-offer-content > img {
    width: 85%;
    max-width: 200px; }
  .promo-offer-content > a {
    font-size: 0.8rem; }
