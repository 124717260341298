.notification-panel-wrapper {
  display: flex;
  align-items: center;
  height: 70px;
  width: 80%;
  border-radius: 6px;
  border: 1px solid var(--primaryColor);
  margin: 0 auto;
  background-color: #ffffff;
  padding: 5px 20px;
  transition: background-color 0.5s ease-in-out; }
  .notification-panel-wrapper.primary {
    background-color: #eaeeff; }
    .notification-panel-wrapper.primary:hover {
      background-color: #eaeeff; }
  .notification-panel-wrapper h3 {
    margin: 0; }
  .notification-panel-wrapper .inner-container {
    display: flex;
    width: 100%;
    color: var(--primaryColor);
    flex-direction: row;
    justify-content: space-between; }
    .notification-panel-wrapper .inner-container .selection-counter {
      margin: 0;
      text-transform: uppercase; }
      .notification-panel-wrapper .inner-container .selection-counter ul {
        list-style: none;
        display: inline-flex;
        align-items: center;
        padding: 0;
        font-size: 12px;
        margin: 0; }
        .notification-panel-wrapper .inner-container .selection-counter ul li.total-selection {
          padding-left: 15px;
          font-size: 28px; }
        .notification-panel-wrapper .inner-container .selection-counter ul li.credit-section {
          font-size: 28px; }
        .notification-panel-wrapper .inner-container .selection-counter ul li .total-selection-key {
          display: inline-block;
          text-align: center;
          max-width: 44px;
          font-size: 10px; }
    .notification-panel-wrapper .inner-container .notification-message {
      max-width: 360px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px; }
      .notification-panel-wrapper .inner-container .notification-message a {
        color: inherit;
        text-decoration: underline; }

.notification-review {
  background-color: red;
  color: white;
  padding: 1px 29px;
  width: 80%;
  margin: 0 auto;
  border-radius: 11px;
  font-size: 0.9rem; }
