.widgets-panel {
  flex: 1;
  min-width: 320px; }

.widgets-panel-link {
  text-align: left;
  font-size: 14px;
  padding: 6px 0;
  margin: 0;
  display: block;
  color: #787878;
  cursor: pointer; }

.widgets-panel-link:hover,
.widgets-panel-link:focus {
  text-decoration: underline; }

.widgets-panel-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 6px 0; }

.widgets-panel-section {
  display: flex;
  flex-direction: row;
  padding: 6px; }

.widgets-panel-section > div {
  padding: 0 12px; }

.widgets-panel-section > div:first-child {
  min-width: 120px; }

.widgets-panel-p {
  font-size: 12px;
  line-height: 14px;
  margin: 0; }

.widgets-panel-qrcode {
  margin-top: 12px; }

.widgets-panel-qrcode > canvas {
  width: 60px !important;
  height: 60px !important; }

#widget-embed {
  display: block;
  margin: 15px auto; }
