@import '../../../../variables';

.container_ga {
  text-align: center;
}

.container_input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_ga {
  padding: 10px;
  font-size: 1.3rem;
}

.button_ga {
  padding: 12px;
  cursor: pointer;
  font-weight: bold;
  color: $primary-text-color;
  background-color: $primary-color;
  font-size: 1.3rem;
  margin-left: 5px;
}

.button_ga:hover {
  box-shadow: 0px 0px 20px $primary-color-light;
}

.help_ga {
  color: $primary-color;
  margin-top: 20px;
  display: inline-block;
}

.help_ga:hover {
  color: $primary-color-light;
}

.help_ga_change {
  cursor: pointer;
  margin-top: 40px;
}

.button_ga_change {
  margin-top: 100px;
}
