.button-link-menu-wrapper {
  display: block;
  width: 100%; }
  .button-link-menu-wrapper .button-link-container {
    display: flex;
    padding: 0 1.5rem;
    align-items: stretch;
    justify-items: stretch;
    padding: 0 1.5rem; }
    .button-link-menu-wrapper .button-link-container .link-radio-option-wrapper {
      display: inline-block;
      width: 258px; }
      .button-link-menu-wrapper .button-link-container .link-radio-option-wrapper .select-field-wrapper {
        width: 100%;
        padding-left: 6px;
        padding-right: 6px;
        margin: 0; }
    .button-link-menu-wrapper .button-link-container .radio-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: space-around; }
      .button-link-menu-wrapper .button-link-container .radio-wrapper > div {
        top: 10px; }

.iconUrlContainer {
  height: 15vh;
  width: 70%;
  background-color: white;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 2vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px; }

.iconContainer {
  height: 50vh;
  width: 90%;
  background-color: white;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 2vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
  overflow-y: scroll;
  padding: 20px; }

.iconContainer > img,
.iconUrlContainer > img {
  object-fit: contain;
  width: 100px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s; }
