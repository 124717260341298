.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 20%; }

.login-input-container {
  display: flex;
  width: 50%;
  max-width: 500px;
  height: 220px;
  padding: 0 24px;
  margin: 0 auto;
  border-radius: 12px;
  text-align: center; }

.login-input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .login-input-group form {
    width: 100%; }
    .login-input-group form .text-field-wrapper {
      width: 100%;
      max-width: 500px; }

.login-header {
  margin: 0 auto;
  text-align: center;
  width: 50%;
  max-width: 500px; }
  .login-header p {
    font-size: 30px;
    color: #1e2023; }

.login-title {
  text-align: center;
  color: #313131;
  font-weight: 700; }

.to-forgotten-password {
  margin: 20px 0; }

@media only screen and (max-width: 600px) {
  .login {
    padding: 12px; }
  .login-input-container {
    padding: 12px;
    width: 100%; }
    .login-input-container form {
      width: 100%; } }
