.reset-password {
  max-height: calc(100vh - 211px);
  overflow-y: auto; }
  .reset-password > div {
    width: 300px;
    margin: 24px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 300px; }

.reset-password-fieldset {
  margin-bottom: 24px; }
