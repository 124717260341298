.icon {
  position: relative;
  display: inline-block;
  color: var(--primaryColor);
  fill: currentcolor; }

.icon.pages,
.icon.pencil,
.icon.app-domain,
.icon.app-icon,
.icon.splash-screen,
.icon.navigation {
  height: 32px;
  width: 32px;
  color: var(--primaryColor); }

.icon.undo {
  height: 18px;
  width: 18px; }

.icon.delete {
  bottom: 1px;
  height: 17px;
  width: 17px;
  color: var(--primaryColor); }

.icon.undo {
  right: 6px; }

.icon.dashboard,
.icon.distribute,
.icon.builder,
.icon.subscribe {
  height: 32px;
  width: 32px; }

.icon.builder {
  position: relative;
  left: 3px; }

.icon.cog {
  height: 20px;
  width: 20px;
  color: var(--primaryColor); }

.icon.drag {
  height: 17px;
  width: 17px;
  color: var(--primaryColor); }

.icon.view,
.icon.tabview,
.icon.homepage {
  color: rgba(65, 65, 65, 0.75);
  bottom: 1px;
  height: 15px;
  width: 15px; }

.icon.pencil {
  height: 34px;
  width: 34px;
  right: 6px; }

.icon.create,
.icon.close {
  height: 20px;
  width: 20px;
  color: var(--primaryColor); }

.icon.back {
  height: 19px;
  width: 19px; }
