.user-details {
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }

.user-details > div {
  margin-left: 48px; }

.username {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0; }
