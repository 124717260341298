.counter {
  padding: 10px 25px;
  text-align: center; }

.counter.border-bottom {
  border-bottom: 4px solid #333; }

.counter-number {
  color: #333;
  margin: 0;
  font-size: 42px;
  font-weight: 700; }

.counter-subtitle {
  color: #333;
  margin: 0;
  font-size: 13px; }

.counter-subtitle-free-plan {
  color: #000000;
  opacity: 0.5;
  margin: 0;
  font-size: 13px; }

.counter-number-free-plan {
  color: #000000;
  opacity: 0.5;
  margin: 0;
  font-size: 42px;
  font-weight: 700; }
