.empty-state {
  > .empty-icon {
    width: 120px;
    height: 120px;
  }
  p {
    font-size: 18px;
    text-align: center;
  }
}
