.create-app-fieldset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -16px;
  width: 100%; }

.create-domain-url {
  color: #555;
  font-size: 21px;
  font-weight: 200; }

.create-domain-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.create-domain-input-container {
  position: relative;
  left: 32px;
  padding: 24px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 6px;
  border: 2px dotted #f2f2f2; }

.create-domain-container:before,
.create-domain-container:after {
  position: relative;
  left: 32px;
  color: #555;
  font-size: 21px;
  font-weight: 200; }

.create-domain-container:after {
  content: '.beezer.com'; }

.create-title-container {
  margin-bottom: 24px; }

@media only screen and (max-width: 600px) {
  .create-domain-container:after {
    left: 0;
    font-size: 18px; }
  .create-domain-input-container {
    left: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none; }
    .create-domain-input-container .text-field-wrapper {
      width: auto; } }

.selectable-card:hover .preview-skin-link {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 200ms; }

.preview-skin-link {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 200ms, opacity 200ms;
  border: 1px solid lightgray;
  border-radius: 25px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  margin: 5px; }
  .preview-skin-link:hover {
    cursor: pointer;
    background-color: rgba(239, 239, 239, 0.5); }
  .preview-skin-link svg {
    width: 16px !important;
    height: 16px !important;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); }

.create-app .create-form {
  max-height: calc(100vh - 300px);
  overflow: auto;
  align-items: flex-start; }

.create-app .buttons-container > div:first-of-type span {
  color: var(--primaryColor) !important; }

.create-app .buttons-container div:last-of-type button {
  background-color: var(--primaryColor) !important; }
