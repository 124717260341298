@import '../../../../_variables.scss';
.form {
  height: 100%;
  overflow: scroll;
  display: flex;
}

.main_container {
  flex: 0.7;
  height: 100%;
  overflow: scroll;
  padding-bottom: 30px;
}

.secondary_container {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.secondary_container > div {
  width: 80%;
  margin-bottom: 80px;
}

.secondary_container > div > div {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f5f9fb;
  box-shadow: 0 42px 64px 0 #e7e7ec;
  width: 80%;
  padding: 78px 20px 20px;
  margin: 30px auto;
  border-radius: 12px;
}

.container input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.title_container {
  align-items: center;
  text-align: center;
  background-color: #f5f9fb;
  box-shadow: 0 42px 64px 0 #e7e7ec;
  width: 80%;
  padding: 20px;
  margin: 30px auto;
  border-radius: 12px;
}

.title_container:first-child {
  padding-top: 78px;
}

.title_container div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
}

.title_container div:nth-child(2) {
  padding-top: 10px;
}

.title_container div input {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 17px;
  border: 1px solid $primary-color;
  outline-color: $primary-color;
  height: 50px;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
}

.title_container div label {
  text-align: left;
}

.button {
  height: 35px;
  padding: 0 40px;
  font-size: 20px;
  font-weight: bold;
  color: $primary-text-color;
  background-color: $primary-color !important;
  border-radius: 22px;
}

.button:hover {
  box-shadow: 0px 0px 20px $primary-color-light;
}
.error {
  color: $error-color;
}

.errorInput {
  border: 1px solid $error-color;
}

.container h2,
.title_container h2,
.title_container div label,
.secondary_container > div > div:first-child {
  color: $primary-color;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

.container h2 {
  margin: 0;
}

.title_container h2 {
  font-size: 32px;
}

.title_container:first-child h2 {
  font-weight: 800;
}
