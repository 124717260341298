.rte {
  min-height: 100%;
  border: none;
  flex: 1; }
  .rte .rte-toolbar {
    position: absolute;
    top: 0;
    z-index: 2; }
    .rte .rte-toolbar [class^='ButtonGroup']:nth-child(3) div {
      z-index: 20; }
      .rte .rte-toolbar [class^='ButtonGroup']:nth-child(3) div [class^='InputPopover'] {
        left: -140px;
        top: 33px; }
        .rte .rte-toolbar [class^='ButtonGroup']:nth-child(3) div [class^='InputPopover']:before, .rte .rte-toolbar [class^='ButtonGroup']:nth-child(3) div [class^='InputPopover']:after {
          left: 147px; }

.align-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px; }
  .align-buttons-container .selected:hover {
    cursor: not-allowed; }

.rte .rte-toolbar {
  margin-top: 50px; }

.rte .rte-editor {
  max-height: calc(100vh - 160px);
  padding-top: 50px; }
  .rte .rte-editor,
  .rte .rte-editor div {
    align-items: stretch;
    display: flex;
    flex-direction: column; }
  .rte .rte-editor div {
    flex: 1 1 100%; }
  .rte .rte-editor [role='textbox'],
  .rte .rte-editor [role='textbox'] div {
    display: block; }
