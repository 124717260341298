.disabled {
  cursor: not-allowed;
}

.section_header {
  margin-bottom: 20px;
  width: 100%;
}

.table_container {
  width: 90% !important;
  max-height: 360px;
  overflow: scroll;
}
