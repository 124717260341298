.create-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 56px; }
  .create-content .legend {
    display: flex;
    justify-content: flex-end;
    line-height: 44px; }
    .create-content .legend img {
      max-height: 32px;
      vertical-align: middle; }
    .create-content .legend span {
      align-items: center;
      display: flex; }
    .create-content .legend strong {
      font-size: 14px;
      line-height: 14px;
      padding: 0 10px;
      text-align: center;
      text-transform: uppercase;
      width: auto; }
  .create-content .subtext {
    text-align: center; }

.create-controls {
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  border-top: 1px solid rgba(196, 196, 196, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  min-height: 80px;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 4; }

.create-content h2 {
  text-align: center;
  font-size: 28px;
  margin: 15px 0; }

.create-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  width: 100%; }
  .create-form .search-input-wrapper {
    width: 50%;
    max-width: 800px;
    min-width: 500px;
    margin: 0.5em auto; }
  .create-form .radio-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .create-form .radio-menu > div > div:first-child {
      background-color: #fff !important; }

.step-enter {
  transform: translateX(1000px);
  opacity: 0.01; }

.step-enter.step-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms ease-in; }

.step-leave {
  opacity: 1;
  transform: translateX(0); }

.step-leave.step-leave-active {
  opacity: 0.01;
  transform: translateX(-1000px);
  transition: all 300ms ease-in; }

@media only screen and (max-width: 600px) {
  .create-content {
    padding: 0 6px 44px; }
    .create-content > div {
      max-width: 100%; }
    .create-content .selectable-card img {
      margin: 0 !important; }
    .create-content .radio-menu {
      max-width: 100%;
      padding: 0; }
    .create-content .selectable-card {
      padding: 12px; }
    .create-content .create-title-container .text-field-wrapper {
      width: 300px; }
    .create-content .buttons-container {
      display: flex;
      align-items: center; }
      .create-content .buttons-container button span {
        padding: 0; } }

@media only screen and (min-width: 600px) {
  .create-content .subtext {
    width: 60%; } }

.create-new-component .selectable-card {
  position: relative;
  padding: 24px;
  width: 100%;
  min-height: 200px;
  height: 100%;
  background: #efeff4;
  border-radius: 3px;
  text-align: center;
  transition: all 300ms linear; }
  .create-new-component .selectable-card .icon {
    color: var(--primaryColor);
    width: 40px;
    height: 30px; }
  .create-new-component .selectable-card .blogIcon {
    color: var(--primaryColor);
    width: 40px;
    height: 39px; }
  .create-new-component .selectable-card .htmlIcon {
    color: var(--primaryColor);
    width: 70px;
    height: 50px;
    fill: none; }
  .create-new-component .selectable-card .courseIcon {
    color: var(--primaryColor);
    width: 40px;
    height: 44px; }
  .create-new-component .selectable-card .customIcon {
    fill: var(--primaryColor) !important; }
  .create-new-component .selectable-card .zoomicon {
    color: var(--primaryColor);
    width: 70px;
    height: 50px; }
  .create-new-component .selectable-card .woocommerceicon {
    color: var(--primaryColor);
    width: 70px;
    height: 40px; }
  .create-new-component .selectable-card > div {
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1em; }
  .create-new-component .selectable-card > div:before {
    background: var(--primaryColor);
    border-radius: inherit;
    bottom: -2px;
    content: '';
    left: -2px;
    opacity: 0;
    position: absolute;
    right: -2px;
    top: -2px;
    transition: opacity 0.4s ease;
    z-index: -1; }
  .create-new-component .selectable-card.active {
    background: var(--primaryColor);
    color: #ffffff; }
    .create-new-component .selectable-card.active > div:before {
      opacity: 1; }
    .create-new-component .selectable-card.active p {
      color: #ffffff; }
    .create-new-component .selectable-card.active svg {
      color: #ffffff;
      fill: #ffffff !important; }
    .create-new-component .selectable-card.active a {
      color: #ffffff; }
  .create-new-component .selectable-card.disabled:hover {
    cursor: pointer; }
  .create-new-component .selectable-card.highlight-border {
    border: 2px dashed #0000a2; }
  .create-new-component .selectable-card.not-selectable {
    cursor: default; }

.create-new-component .selectable-card.disabled {
  cursor: default;
  position: relative; }
  .create-new-component .selectable-card.disabled:hover, .create-new-component .selectable-card.disabled:focus {
    background-clip: border-box;
    background-color: #f1f1f1;
    box-shadow: none; }
  .create-new-component .selectable-card.disabled:hover > div,
  .create-new-component .selectable-card.disabled:hover .disabled-notice, .create-new-component .selectable-card.disabled:focus > div,
  .create-new-component .selectable-card.disabled:focus .disabled-notice, .create-new-component .selectable-card.disabled:active > div,
  .create-new-component .selectable-card.disabled:active .disabled-notice {
    opacity: 1; }
  .create-new-component .selectable-card.disabled > div {
    opacity: 0.3;
    transition: opacity 300ms linear; }
  .create-new-component .selectable-card.disabled.coming-soon.coming-soon > div {
    opacity: 0.9; }
  .create-new-component .selectable-card.disabled .coming-soon {
    height: 120px;
    left: 50%;
    margin: -60px;
    position: absolute;
    top: 74px;
    width: 120px; }
  .create-new-component .selectable-card.disabled .disabled-icon {
    position: absolute;
    right: -15px;
    top: -15px;
    width: 45px;
    z-index: 3; }
    .create-new-component .selectable-card.disabled .disabled-icon img {
      width: 45px; }
  .create-new-component .selectable-card.disabled .disabled-notice {
    align-items: center;
    background: rgba(241, 241, 241, 0.8);
    bottom: 0;
    display: flex;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 16px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.4s ease;
    z-index: 2; }
    .create-new-component .selectable-card.disabled .disabled-notice .bold {
      font-weight: bold; }
    .create-new-component .selectable-card.disabled .disabled-notice .disabled-notice-message {
      background: #fff;
      width: 100%;
      border-radius: 3px;
      font-size: 12px;
      line-height: 20px;
      margin: 10px 0;
      text-align: center;
      padding: 10px; }
      .create-new-component .selectable-card.disabled .disabled-notice .disabled-notice-message .disabled-buttons {
        margin-top: 10px; }

.create-new-component .buttons-container > div:first-of-type span {
  color: var(--primaryColor) !important; }

.create-new-component .buttons-container div:last-of-type button {
  background-color: var(--primaryColor) !important; }
