.form-container {
  width: auto !important; }

.rte {
  min-height: 100%;
  flex: 1;
  border-radius: 3px;
  border: 1px solid #ddd; }
  .rte .rte-toolbar {
    position: relative;
    z-index: 2; }
    .rte .rte-toolbar [class^='ButtonGroup']:nth-child(3) div {
      z-index: 20; }
      .rte .rte-toolbar [class^='ButtonGroup']:nth-child(3) div [class^='InputPopover'] {
        left: -140px;
        top: 33px; }
        .rte .rte-toolbar [class^='ButtonGroup']:nth-child(3) div [class^='InputPopover']:before, .rte .rte-toolbar [class^='ButtonGroup']:nth-child(3) div [class^='InputPopover']:after {
          left: 147px; }

.align-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px; }
  .align-buttons-container .selected:hover {
    cursor: not-allowed; }

.rte .rte-toolbar {
  margin-top: 50px; }

.rte .rte-editor {
  max-height: 100%;
  padding-top: 50px; }
  .rte .rte-editor,
  .rte .rte-editor div {
    align-items: stretch;
    display: flex;
    flex-direction: column; }
  .rte .rte-editor div {
    flex: 1 1 100%; }
  .rte .rte-editor [role='textbox'],
  .rte .rte-editor [role='textbox'] div {
    display: block; }

.share-email-form {
  min-width: 0px !important;
  overflow: scroll; }
  .share-email-form .message-editor-container {
    position: relative;
    margin-top: 15px; }
    .share-email-form .message-editor-container .align-buttons-container {
      position: absolute;
      width: 100%; }
