.account {
  display: flex; }
  .account .account-section {
    width: 50%;
    padding: 0 12px; }
  .account .header {
    padding: 12px 0;
    margin: 24px 0;
    border-bottom: 1px solid #e2e2e2; }
  .account .delete-user-settings {
    display: flex;
    flex-direction: column; }
