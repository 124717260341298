@import '../../../../variables';

.button {
  border-radius: 5px;
  font-weight: 700;
  color: $primary-text-color;
  padding: 10px;
  background-color: $primary-color;
  transition: all 0.4s;
  cursor: pointer;
  margin: auto;
}

.button:hover {
  box-shadow: 0px 0px 20px $primary-color-light;
}

.capitilize {
  text-transform: capitalize;
}

.description {
  line-height: 3 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block !important;
  width: 100%;
  text-align: start;
}
