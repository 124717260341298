.icon {
  position: relative;
  display: inline-block;
  color: #fff;
  fill: currentcolor;
  height: 24px;
  width: 24px;
}

.st1{fill:none;stroke:#281FD6;stroke-width:4.4508;stroke-miterlimit:10;}

.st2{fill:none;stroke:#281FD6;stroke-width:1.9453;stroke-miterlimit:10;}