.marketplace-intro-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 48px;
  text-align: center; }
  .marketplace-intro-content img {
    width: 120px;
    height: 120px;
    margin-bottom: 2rem; }
  .marketplace-intro-content p {
    font-size: 18px;
    text-align: center; }
