@import '../../../../variables';
.tax {
  display: flex;
  align-items: center;
  label {
    margin-left: 20px;
  }
}

.stripe {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem 0;
}

.currency {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.currency h4 {
  margin-right: 20px;
}

.stock {
  margin: 1rem 0;
}

.stock span {
  font-family: 'Raleway', 'Karla', sans-serif !important;
  font-weight: bold !important;
}

.button {
  height: 35px;
  padding: 0 40px;
  font-size: 20px;
  font-weight: bold;
  color: $primary-text-color;
  background-color: $primary-color !important;
}

.stock label {
  margin-left: 0 !important;
}

.container form {
  margin-left: 30px;
  margin-bottom: 40px;
}

.container input {
  margin-left: 10px;
}

.settings_header {
  border-bottom: 2px solid #c8c8c8;
  width: 100%;
  padding-bottom: 15px;
}

.settings_header_custom {
  color: $primary-color;
  font-size: 24px;
  font-family: Montserrat;
}

.container {
  width: 100%;
}

.disabled_container {
  opacity: 0.5;
  pointer-events: none;
}

.settings_label {
  margin-left: 0px !important;
}
.container label {
  margin-left: 20px;
}
