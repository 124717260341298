.billing:not(.thing) {
  max-height: calc(100vh - 211px);
  overflow-y: auto; }
  .billing:not(.thing) > div {
    float: left;
    padding: 0 12px; }
  .billing:not(.thing) .card {
    width: 50%; }
  .billing:not(.thing) .invoice {
    width: 50%; }

.billing-data div {
  width: 33.33%; }

.header {
  padding: 12px 0;
  margin: 24px 0;
  border-bottom: 1px solid #e2e2e2; }

.invoice-amount-link {
  display: flex !important;
  flex-direction: column; }

.coupon-expiry-msg {
  font-size: 0.8rem; }
