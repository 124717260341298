.color-picker-wrapper {
  display: none;
  overflow: hidden;
  position: fixed;
  z-index: 11;
  top: 26px;
  left: 2px;
  height: 0px;
  width: 0px; }

.color-picker-wrapper.active {
  height: auto;
  width: auto;
  overflow: visible;
  display: block; }

.color-picker-wrapper:before {
  position: absolute;
  height: 14px;
  width: 10px;
  top: -5px;
  left: 3px;
  z-index: 0;
  transform: rotate(45deg);
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 0px 2px, rgba(0, 0, 0, 0.298039) 0px 4px 4px; }

.design-color {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px; }
  .design-color.disabled .design-color-label {
    color: rgba(0, 0, 0, 0.4); }
  .design-color.disabled button:disabled {
    opacity: 0.5; }

.design-color-label {
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase; }
