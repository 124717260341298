.engage-container .push-header-actions {
  display: flex; }
  .engage-container .push-header-actions .submit-button-container {
    border-left: 1px solid #d4d4d4;
    margin-left: 15px;
    padding-left: 10px;
    align-self: center; }

.engage-container .options-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .engage-container .options-container .MuiCheckbox-root,
  .engage-container .options-container .action-menu-container {
    width: 24px;
    height: 24px; }

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 50px;
  border-radius: 5px; }
  .empty-state img {
    width: 25%;
    margin: 30px; }
  .empty-state h5 {
    font-weight: 500; }
