.subscription-error-msg {
  color: #f44336;
  text-align: center; }

.subscription-link {
  text-align: center; }

.item-order {
  display: flex;
  justify-content: space-between;
  column-gap: 0.6rem;
  align-items: center;
  width: 324px;
  margin: 0 auto; }
