.push-title {
  font-size: 0.83rem;
  color: #444;
  margin: 0; }

.push__tooltip {
  position: relative;
  right: 30px; }

.notification-panel .notification-preview-wrapper {
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 10px 0; }

.recipients-container {
  display: flex;
  align-items: center; }

.num-recipients {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase; }
  .num-recipients > strong {
    color: #333;
    font-size: 52px;
    font-weight: 800;
    font-family: inherit;
    margin-top: 0.5em; }

.error-container {
  text-align: center; }

.notification-wrapper {
  position: absolute;
  width: 100%;
  bottom: -30px;
  left: 0;
  z-index: 1; }

.submit-button-container.disabled span:first-child {
  cursor: not-allowed !important; }
  .submit-button-container.disabled span:first-child div,
  .submit-button-container.disabled span:first-child button {
    cursor: not-allowed !important; }

.form-container {
  padding: 0;
  padding-top: 15px; }
  .form-container .push-message-container {
    width: 100%;
    margin-bottom: 15px; }
  .form-container .text-field-wrapper {
    margin-bottom: 0;
    margin-top: -5px; }
  .form-container .notification-link-menu-wrapper:last-child {
    margin-bottom: 30px; }
  .form-container .notification-link-menu-wrapper .notification-link-container {
    padding-left: 6px; }

.panel-scroll-area .row:first-child {
  margin: 0; }

.notification-container__scrollable {
  overflow: scroll;
  height: 900px; }
