.nested-pages-draggable {
  background: #e1dede; }

.nested-page {
  padding: 20px;
  padding-right: 0px;
  width: 100%;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  color: var(--primaryColor); }
  .nested-page > span {
    width: 100%;
    text-align: center; }

.page-title-container {
  display: flex; }
