.subheader {
  font-size: 20px;
  font-weight: 600;
  margin-left: 0.2rem;
  color: #333; }
  .subheader b {
    font-family: 'Raleway', sans-serif !important;
    margin-left: 0.6rem;
    font-size: 20px;
    font-weight: 600; }
  .subheader span {
    font-family: 'Raleway', sans-serif !important; }
