.icon {
  position: relative;
  display: inline-block;
  color: var(--primaryColor);
  fill: currentcolor;
  height: 32px;
  width: 32px; }

.icon.inventory,
.icon.drivers,
.icon.settings {
  color: var(--primaryColor);
  height: 32px;
  width: 32px; }

.icon.orders {
  height: 40px;
  width: 40px;
  padding-left: 4px; }
