.pgmol-newsletter-container > .jodit-react-container {
  margin-top: 2vh;
  width: 96%;
  border-radius: 5px; }

.pgmol-newsletter-container .jodit-wysiwyg {
  min-height: 110px !important; }

.pgmol-newsletter-container .jodit-wysiwyg h1,
.pgmol-newsletter-container .jodit-wysiwyg h2,
.pgmol-newsletter-container .jodit-wysiwyg h3,
.pgmol-newsletter-container .jodit-wysiwyg h4,
.pgmol-newsletter-container .jodit-wysiwyg h5,
.pgmol-newsletter-container .jodit-wysiwyg h6,
.pgmol-newsletter-container .jodit-wysiwyg p,
.pgmol-newsletter-container .jodit-wysiwyg span {
  margin: 0; }

.pgmol-newsletter-container .jodit-wysiwyg p:has(strong):has(.pgmol-margin-top),
.pgmol-newsletter-container
.jodit-wysiwyg
h1:has(strong):has(.pgmol-margin-top),
.pgmol-newsletter-container
.jodit-wysiwyg
h2:has(strong):has(.pgmol-margin-top),
.pgmol-newsletter-container
.jodit-wysiwyg
h3:has(strong):has(.pgmol-margin-top),
.pgmol-newsletter-container
.jodit-wysiwyg
h4:has(strong):has(.pgmol-margin-top),
.pgmol-newsletter-container
.jodit-wysiwyg
h5:has(strong):has(.pgmol-margin-top),
.pgmol-newsletter-container
.jodit-wysiwyg
h6:has(strong):has(.pgmol-margin-top) {
  margin-top: 10px; }

.pgmol-newsletter-container .jodit-wysiwyg p:has(span):has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h1:has(span):has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h2:has(span):has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h3:has(span):has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h4:has(span):has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h5:has(span):has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h6:has(span):has(.pgmol-margin-top) {
  margin-top: 10px; }

.pgmol-newsletter-container .jodit-wysiwyg p:has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h1:has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h2:has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h3:has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h4:has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h5:has(.pgmol-margin-top),
.pgmol-newsletter-container .jodit-wysiwyg h6:has(.pgmol-margin-top) {
  margin-top: 10px; }

.pgmol-newsletter-container
.jodit-wysiwyg
p:has(strong):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h1:has(strong):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h2:has(strong):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h3:has(strong):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h4:has(strong):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h5:has(strong):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h6:has(strong):has(.pgmol-margin-bottom) {
  margin-bottom: 10px; }

.pgmol-newsletter-container
.jodit-wysiwyg
p:has(span):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h1:has(span):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h2:has(span):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h3:has(span):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h4:has(span):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h5:has(span):has(.pgmol-margin-bottom),
.pgmol-newsletter-container
.jodit-wysiwyg
h6:has(span):has(.pgmol-margin-bottom) {
  margin-bottom: 10px; }

.pgmol-newsletter-container .jodit-wysiwyg p:has(.pgmol-margin-bottom),
.pgmol-newsletter-container .jodit-wysiwyg h1:has(.pgmol-margin-bottom),
.pgmol-newsletter-container .jodit-wysiwyg h2:has(.pgmol-margin-bottom),
.pgmol-newsletter-container .jodit-wysiwyg h3:has(.pgmol-margin-bottom),
.pgmol-newsletter-container .jodit-wysiwyg h4:has(.pgmol-margin-bottom),
.pgmol-newsletter-container .jodit-wysiwyg h5:has(.pgmol-margin-bottom),
.pgmol-newsletter-container .jodit-wysiwyg h6:has(.pgmol-margin-bottom) {
  margin-bottom: 10px; }

.pgmol-newsletter-container
.jodit-wysiwyg
p:has(strong):has(.pgmol-margin-left),
.pgmol-newsletter-container
.jodit-wysiwyg
h1:has(strong):has(.pgmol-margin-left),
.pgmol-newsletter-container
.jodit-wysiwyg
h2:has(strong):has(.pgmol-margin-left),
.pgmol-newsletter-container
.jodit-wysiwyg
h3:has(strong):has(.pgmol-margin-left),
.pgmol-newsletter-container
.jodit-wysiwyg
h4:has(strong):has(.pgmol-margin-left),
.pgmol-newsletter-container
.jodit-wysiwyg
h5:has(strong):has(.pgmol-margin-left),
.pgmol-newsletter-container
.jodit-wysiwyg
h6:has(strong):has(.pgmol-margin-left) {
  margin-left: 10px; }

.pgmol-newsletter-container .jodit-wysiwyg p:has(span):has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h1:has(span):has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h2:has(span):has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h3:has(span):has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h4:has(span):has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h5:has(span):has(.pgmol-margin-left),
.pgmol-newsletter-container
.jodit-wysiwyg
h6:has(span):has(.pgmol-margin-left) {
  margin-left: 10px; }

.pgmol-newsletter-container .jodit-wysiwyg p:has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h1:has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h2:has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h3:has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h4:has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h5:has(.pgmol-margin-left),
.pgmol-newsletter-container .jodit-wysiwyg h6:has(.pgmol-margin-left) {
  margin-left: 10px; }

.pgmol-newsletter-container
.jodit-wysiwyg
p:has(strong):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h1:has(strong):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h2:has(strong):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h3:has(strong):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h4:has(strong):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h5:has(strong):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h6:has(strong):has(.pgmol-margin-right) {
  margin-right: 10px; }

.pgmol-newsletter-container .jodit-wysiwyg p:has(span):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h1:has(span):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h2:has(span):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h3:has(span):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h4:has(span):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h5:has(span):has(.pgmol-margin-right),
.pgmol-newsletter-container
.jodit-wysiwyg
h6:has(span):has(.pgmol-margin-right) {
  margin-right: 10px; }

.pgmol-newsletter-container .jodit-wysiwyg p:has(.pgmol-margin-right),
.pgmol-newsletter-container .jodit-wysiwyg h1:has(.pgmol-margin-right),
.pgmol-newsletter-container .jodit-wysiwyg h2:has(.pgmol-margin-right),
.pgmol-newsletter-container .jodit-wysiwyg h3:has(.pgmol-margin-right),
.pgmol-newsletter-container .jodit-wysiwyg h4:has(.pgmol-margin-right),
.pgmol-newsletter-container .jodit-wysiwyg h5:has(.pgmol-margin-right),
.pgmol-newsletter-container .jodit-wysiwyg h6:has(.pgmol-margin-right) {
  margin-right: 10px; }
