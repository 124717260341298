.hidden-input {
  display: none; }

.pdf-container {
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.pdf-card {
  border: 1.5px solid #ccc;
  max-width: 100%;
  position: relative;
  padding: 15px 10px;
  margin: 1vh 0.5vw;
  border-radius: 10px;
  cursor: pointer; }

.pdf-card:hover {
  border: 2px dashed var(--primaryColor); }

.delete-icon {
  position: absolute;
  right: 0;
  top: 0; }

.delete-icon > button > div > svg:hover {
  color: red !important; }

.upload_loader {
  margin-top: 1vh; }

.inner-pdf-container {
  max-width: 90%; }

.inner-pdf-container > h4 {
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.pdf-title-input {
  display: flex;
  align-items: center; }

.upload_dialog_main_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  padding: 2vh; }

.url_upload_button_file_2 {
  font-weight: bold;
  position: relative;
  display: inline-block;
  color: #ffffff !important;
  background-color: var(--primaryColor) !important;
  cursor: pointer !important;
  padding: 10px 40px;
  border-radius: 10px; }

.upload_dialog_files_list {
  width: 50%;
  height: 40vh;
  overflow-y: scroll; }

.upload_dialog_files_list > p {
  text-align: left; }

.upload_dialog_files_list::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff; }

.upload_dialog_files_list::-webkit-scrollbar-thumb {
  background-color: #b3a9a9; }

.upload_dialog_files_list::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  outline: none; }
