.pgmol-blog-modal-main-container > div > div {
  width: 100% !important;
  max-width: 1200px !important; }

.pgmol-blog-toggle-save-container {
  padding-top: 2vh;
  margin-bottom: 2vh; }
  .pgmol-blog-toggle-save-container label {
    color: #291fd6 !important;
    font-weight: bold;
    font-size: 1.2rem !important; }

.blog-modal-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 2vw; }
  .blog-modal-container > .text-field-wrapper {
    margin: 0 !important; }
  .blog-modal-container h5 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: black;
    margin-bottom: 0; }

.pgmol-blog-autocomplete {
  z-index: 2000;
  width: 45%;
  margin-top: 1vh;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: black; }
  .pgmol-blog-autocomplete input::placeholder {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: black !important; }

.pgmol-blog-toggle {
  width: 45% !important;
  margin: 1vh 0; }

.pgmol-blog-editor-container {
  width: 95%; }

.pgmol-blog-header-container {
  display: flex;
  align-items: center; }
  .pgmol-blog-header-container > div {
    flex: 1; }

.pgmol-blog-categories-modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px; }
  .pgmol-blog-categories-modal-container button {
    margin-left: 0 !important; }
  .pgmol-blog-categories-modal-container h4 {
    font-family: 'Raleway', 'Karla', sans-serif;
    font-weight: 600; }

.blog-category-delete-modal {
  border: 5px solid red !important;
  z-index: 9999 !important; }

.edit-button {
  border-radius: 5px;
  font-weight: 700;
  color: #fff;
  padding: 10px;
  background-color: #291fd7;
  transition: all 0.4s;
  cursor: pointer;
  margin: auto; }

.edit-button:hover {
  box-shadow: 0px 0px 20px #291fd7; }

.pgmol-blog-table .MuiPaper-root {
  height: 58vh !important;
  overflow-y: scroll; }

.pgmol-blog-table tbody th {
  text-align: left !important; }

.jodit-add-new-line {
  display: none !important; }
