.height-100 {
  height: 100%; }

#status-text {
  display: block;
  margin: 10px; }

.inner-layout-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  overflow: hidden; }
  .inner-layout-container .banner-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #261ed5;
    color: #ffffff;
    margin: 20px; }
    .inner-layout-container .banner-section .logo {
      width: 150px; }
    .inner-layout-container .banner-section .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content; }
  .inner-layout-container .form-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 80px;
    margin: 20px; }
  .inner-layout-container .form-layout .inner-layout-form-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .inner-layout-container .form-layout .inner-layout-form-container > div {
      width: 100%; }
    .inner-layout-container .form-layout .inner-layout-form-container .form-container {
      padding: 0px;
      align-items: flex-start; }
    .inner-layout-container .form-layout .inner-layout-form-container .text-field-wrapper {
      width: 100%;
      padding: 0; }
  .inner-layout-container h4 {
    padding-bottom: 12px;
    margin-bottom: 10px;
    font-weight: 300;
    text-align: left;
    font-size: 35px;
    width: 80%; }
  @media only screen and (max-width: 940px) {
    .inner-layout-container {
      flex-direction: column; } }

.header-text {
  font-size: 2rem;
  text-align: center;
  font-weight: bold; }

.subheader-text {
  font-size: 1.5rem !important;
  text-align: center;
  font-weight: bold; }

@media only screen and (max-width: 600px) {
  .inner-layout-container {
    overflow: auto; }
    .inner-layout-container h4 {
      margin-left: 0 !important;
      margin-right: 0 !important; } }

@media only screen and (min-width: 350px) and (max-width: 600px) {
  .inner-layout-container {
    padding: 24px; }
    .inner-layout-container .form-layout {
      padding: 10px; }
    .inner-layout-container .banner-section {
      margin: 0; }
    .inner-layout-container > .row,
    .inner-layout-container .center-xs {
      width: 100%; }
    .inner-layout-container h4 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
  .login .login-header {
    width: 100%; } }

@media only screen and (max-width: 350px) {
  .inner-layout-container {
    width: 100%;
    padding: 20px;
    margin: 0; } }
