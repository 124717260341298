.form {
  height: 100%;
  overflow: scroll;
  display: flex;
}

.main_container {
  flex: 0.7;
  height: 100%;
  overflow: scroll;
  padding-bottom: 30px;
  padding-top: 60px;
}

.secondary_container {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}

.secondary_container > div {
  width: 80%;
}

.secondary_container > div > div {
  width: 100%;
}

.image_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f5f9fb;
  box-shadow: 0 2px 4px 0 #e7e7ec;
  width: 80%;
  padding: 20px;
  margin: 30px auto;
}

.image_container input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.input_container {
  align-items: center;
  text-align: center;
  background-color: #f5f9fb;
  box-shadow: 0 2px 4px 0 #e7e7ec;
  width: 80%;
  padding: 20px;
  margin: 30px auto;
}

.input_container div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 40px 40px;
}

.input_container div input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.input_container div label {
  text-align: left;
}

.button {
  height: 35px;
  padding: 0 40px;
  font-size: 20px;
  font-weight: bold;
  color: #ffbddb;
  background-color: #261ed5 !important;
  border-radius: 20px;
  margin-top: 40px;
}

.button:hover {
  box-shadow: 0px 0px 20px #ffbddb;
}
