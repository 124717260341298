.filter-popup {
  padding: 40px 48px 16px 48px; }
  .filter-popup .search-input-wrapper {
    margin: 0;
    margin-bottom: 14px;
    height: 43px; }
    .filter-popup .search-input-wrapper .search {
      outline: none;
      border-radius: 17px;
      padding: 6px 0;
      padding-left: 50px;
      color: #707070;
      font-size: 18px;
      font-family: "Raleway", sans-serif;
      line-height: 24px;
      width: 100%;
      height: 100%;
      border: 1px solid #707070; }
    .filter-popup .search-input-wrapper .search-icon {
      position: absolute;
      left: 20px;
      top: 10px;
      height: 25px;
      width: 25px;
      color: #707070; }
  .filter-popup .filter-items {
    overflow: scroll;
    height: 289px;
    margin-bottom: 25px;
    overflow-x: hidden; }
    .filter-popup .filter-items .filter-item {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 42px;
      background-color: transparent;
      color: #707070;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 0; }
      .filter-popup .filter-items .filter-item .checkbox {
        opacity: 0;
        position: absolute; }
        .filter-popup .filter-items .filter-item .checkbox:checked + label .custom-checkbox {
          background-color: var(--primaryColor);
          border-color: var(--primaryColor); }
        .filter-popup .filter-items .filter-item .checkbox:checked + label .custom-checkbox::after {
          opacity: 1; }
      .filter-popup .filter-items .filter-item .label {
        font-family: 'Raleway', sans-serif;
        font-size: 16px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative; }
        .filter-popup .filter-items .filter-item .label .custom-checkbox {
          display: inline-block;
          width: 28px;
          height: 28px;
          margin-right: 12px;
          border: 1px solid #707070;
          border-radius: 8px;
          cursor: pointer;
          position: relative;
          transition: 300ms ease-in-out; }
        .filter-popup .filter-items .filter-item .label .custom-checkbox:after {
          border: 2px solid #fff;
          border-top: none;
          border-right: none;
          content: '';
          height: 5px;
          left: 6px;
          opacity: 0;
          position: absolute;
          top: 8px;
          transform: rotate(-45deg);
          width: 12px;
          transition: 300ms ease-in-out; }
  .filter-popup .divider {
    margin: 0;
    margin-bottom: 16px; }
  .filter-popup .filter-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 38px;
    background: transparent; }
    .filter-popup .filter-controls button {
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 17px; }
      .filter-popup .filter-controls button span {
        font-size: 16px;
        text-transform: none;
        letter-spacing: -1px; }
