.payment {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.payment .subtitle {
  margin: 24px 0; }

.price {
  width: 100%;
  font-weight: 400; }
  .price img {
    margin: 6px; }

.terms-and-conditions {
  margin: 36px 0;
  padding: 8px 0;
  width: 340px; }

.lock-container {
  color: #098cd8;
  margin-top: 20px; }
  .lock-container h4 {
    font-size: 14px;
    margin: 0px; }
  .lock-container img {
    height: 50px;
    width: 50px; }
  @media (min-width: 600px) {
    .lock-container {
      margin-top: -85px;
      width: 600px; } }

.lock {
  float: right;
  width: 200px; }

.promo-box {
  display: flex;
  flex-direction: column; }

.promo_success_box {
  color: #2e7d32;
  display: flex;
  flex-direction: column; }

.promo_error_box {
  color: #d32f2f;
  font-size: 0.8rem; }

.promo-box-input {
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 14px; }
  .promo-box-input > * {
    margin-right: 1rem !important; }

.promo-box-msg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8rem; }
  .promo-box-msg > * {
    margin-right: 1rem !important; }

.apply-promo-btn {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important; }
