.credit-card-container {
  text-align: center;
  margin: 24px auto; }

.credit-card {
  width: 350px;
  display: block;
  margin: 36px auto; }

.react-credit-card__container {
  position: relative;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  width: 350px;
  height: 200px; }

.react-credit-card {
  border-radius: 10px;
  line-height: 1;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 315px;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }

.react-credit-card--flipped {
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg); }

.react-credit-card__front,
.react-credit-card__back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 10px;
  background: #DDDDDD;
  z-index: 0; }

.react-credit-card__back {
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg); }

.react-credit-card__back:before {
  content: " ";
  display: block;
  background-color: #FFF;
  background-image: -webkit-linear-gradient(#FFF, #FFF);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#FFF));
  background-image: linear-gradient(#FFF, #FFF);
  width: 80%;
  height: 16%;
  position: absolute;
  top: 40%;
  left: 2%; }

.react-credit-card__display {
  color: white;
  font-weight: normal;
  opacity: 0.5;
  -webkit-transition: opacity 400ms linear;
  transition: opacity 400ms linear; }

.react-credit-card__logo {
  position: absolute;
  right: 0;
  top: 0; }

.react-credit-card__number {
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 24px;
  clear: both;
  margin-bottom: 30px; }

.react-credit-card__name {
  text-transform: uppercase;
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 20px;
  max-height: 45px;
  position: absolute;
  bottom: 0;
  width: 190px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em; }

.react-credit-card__expiry {
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  letter-spacing: 0em;
  position: relative;
  float: right;
  width: 25%; }

.react-credit-card__expiry:before {
  content: attr(data-before);
  margin-bottom: 2px;
  font-size: 7px;
  text-transform: uppercase; }

.react-credit-card__expiry:after {
  position: absolute;
  content: attr(data-after);
  text-align: right;
  right: 100%;
  margin-right: 5px;
  margin-top: 2px;
  bottom: 0; }

.react-credit-card__expiry:before, .react-credit-card__expiry:after {
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 7px;
  white-space: pre;
  display: block;
  opacity: 0.5; }

.react-credit-card--focused {
  opacity: 1;
  font-weight: 700; }

.react-credit-card__lower {
  width: 80%;
  position: absolute;
  left: 10%;
  bottom: 30px; }

.react-credit-card__shiny {
  width: 50px;
  height: 35px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #CCC;
  position: relative; }

.react-credit-card__shiny:after {
  content: attr(data-after);
  position: absolute;
  left: 120%;
  top: 5%;
  color: white;
  font-size: 7px;
  width: 230px;
  opacity: 0.5; }

.react-credit-card__shiny:before {
  content: " ";
  display: block;
  width: 70%;
  height: 60%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #d9d9d9;
  position: absolute;
  top: 20%; }

.react-credit-card__bar {
  background-color: #444;
  background-image: -webkit-linear-gradient(#444, #333);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#333));
  background-image: linear-gradient(#444, #333);
  width: 100%;
  height: 20%;
  position: absolute;
  top: 10%; }

.react-credit-card__back .react-credit-card__shiny {
  position: absolute;
  top: 66%;
  left: 2%; }

.react-credit-card__cvc {
  position: absolute;
  top: 40%;
  left: 85%;
  -webkit-transition-delay: 600ms;
  transition-delay: 600ms;
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 14px; }

.react-credit-card__cvc_front {
  position: absolute;
  top: 19%;
  right: 18%;
  -webkit-transition-delay: 600ms;
  transition-delay: 600ms;
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 14px; }

.react-credit-card--unknown {
  -webkit-box-shadow: none;
  box-shadow: none; }

.react-credit-card--unknown .react-credit-card__logo {
  visibility: hidden; }

.react-credit-card--dankort .react-credit-card__front,
.react-credit-card--dankort .react-credit-card__back {
  background: #0055C7; }

.react-credit-card--discover .react-credit-card__front,
.react-credit-card--discover .react-credit-card__back {
  background: #86B8CF; }

.react-credit-card--mastercard .react-credit-card__front,
.react-credit-card--mastercard .react-credit-card__back {
  background: #0061A8; }

.react-credit-card--visa .react-credit-card__front,
.react-credit-card--visa .react-credit-card__back {
  background: #191278; }

.react-credit-card--amex .react-credit-card__front,
.react-credit-card--amex .react-credit-card__back {
  background-color: #108168; }
