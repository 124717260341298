.ReactCrop,
.ReactCrop__image {
  max-height: 489px; }

.url_upload_container {
  display: flex; }

.url_upload_input {
  width: 75%;
  padding: 10px 15px; }

.url_upload_button {
  font-weight: bold;
  position: relative;
  display: inline-block;
  color: #ffffff !important;
  background-color: var(--primaryColor) !important;
  cursor: pointer !important;
  flex: 1; }

.url_upload_button_file {
  font-weight: bold;
  position: relative;
  display: inline-block;
  color: #ffffff !important;
  background-color: var(--primaryColor) !important;
  cursor: pointer !important;
  padding: 50px;
  width: 100%; }

.url_upload_or_text {
  font-weight: bold; }
