.cta-btn-outline {
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 38px;
  padding: 6px; }
  .cta-btn-outline > span {
    padding: 6px;
    display: inline-block;
    border-radius: 34px;
    background-color: #e4e4e4; }
