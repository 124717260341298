.icon {
  position: relative;
  display: inline-block;
  color: #414141;
  fill: currentcolor; }

.icon.vote-icon,
.icon.rating-icon,
.icon.push-icon,
.icon.push-history-icon,
.icon.form-icon,
.zoom-icon,
.icon.livestream-icon
.forum-icon,
.app-analytics-icon {
  color: var(--primaryColor);
  height: 32px;
  width: 32px; }

.empty-icon {
  width: 200px;
  height: 200px; }

.push-icon {
  width: 34px;
  height: 34px; }

.app-analytics-icon {
  position: relative;
  left: -5px; }
