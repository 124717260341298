.bookingContainer {
  padding: 1rem; }

.actionContainer {
  display: flex;
  justify-content: center;
  align-items: center; }

.bookingLoadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.bookingCard {
  border: 1px solid #959292c9;
  padding: 32px;
  border-radius: 40px;
  height: fit-content;
  min-height: 280px;
  width: 60%;
  max-width: 900px;
  display: grid;
  grid-template-rows: auto 16px 32px;
  grid-template-columns: 189px auto;
  column-gap: 16px;
  row-gap: 16px;
  position: relative; }
  .bookingCard .action-container {
    width: 100%;
    grid-row: 3/4;
    grid-column: 1/4;
    display: flex;
    justify-content: center; }
    .bookingCard .action-container .booking-cancel-btn {
      width: fit-content;
      padding: 16px;
      align-items: center;
      border-radius: 18px;
      height: 48px;
      position: absolute;
      font-size: 15px;
      color: #ffffff;
      background: #d32f2f; }
  .bookingCard .title {
    font-size: 24px;
    width: 100%;
    grid-row: 1/2;
    grid-column: 1/3;
    display: flex;
    align-items: center; }
  .bookingCard .date-time-container {
    border-radius: 40px;
    grid-column: 1/2;
    grid-row: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border: 1px solid #323232; }
    .bookingCard .date-time-container > .date {
      font-size: 40px;
      width: 120px;
      padding: 10px;
      word-break: break-word;
      white-space: break-spaces;
      text-align: center;
      border-bottom: 1px solid #505057; }
    .bookingCard .date-time-container > .time {
      padding: 16px;
      font-size: 20px; }
  .bookingCard .booking-details {
    grid-column: 2/3;
    width: 100%;
    height: 50%;
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    row-gap: 8px; }
    .bookingCard .booking-details .staff {
      display: flex;
      column-gap: 16px;
      align-items: center; }
      .bookingCard .booking-details .staff > span {
        font-size: 24px; }
      .bookingCard .booking-details .staff > .change-staff-btn {
        color: var(--primaryColor);
        text-decoration: underline;
        cursor: pointer;
        font-size: 18px; }
    .bookingCard .booking-details > .customer-details-btn {
      color: var(--primaryColor);
      text-decoration: underline;
      cursor: pointer;
      font-size: 18px; }
    .bookingCard .booking-details > .status {
      font-size: 20px; }
      .bookingCard .booking-details > .status .success {
        color: #2e7d32; }
      .bookingCard .booking-details > .status .error {
        color: #d32f2f; }
    .bookingCard .booking-details > .appointment-name {
      font-size: 36px; }
    .bookingCard .booking-details > .location {
      display: flex;
      align-items: center;
      color: #837676;
      font-size: 22px; }
    .bookingCard .booking-details .booked-on {
      color: #837676;
      font-size: 22px; }

.bookingCustomerStaff {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  height: 50%;
  width: 100%;
  column-gap: 32px; }
  .bookingCustomerStaff .customerCard,
  .bookingCustomerStaff .staffCard {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    box-shadow: -5px 5px 15px 0px #959292c9;
    border-radius: 40px;
    padding: 32px;
    background: #dfdfdf; }
    .bookingCustomerStaff .customerCard > .title,
    .bookingCustomerStaff .staffCard > .title {
      font-size: 24px;
      margin-bottom: 10px; }
    .bookingCustomerStaff .customerCard > .image-container,
    .bookingCustomerStaff .staffCard > .image-container {
      height: 100px;
      width: 100px; }
      .bookingCustomerStaff .customerCard > .image-container > img,
      .bookingCustomerStaff .staffCard > .image-container > img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 50%; }
    .bookingCustomerStaff .customerCard > .booking-change-staff-btn,
    .bookingCustomerStaff .staffCard > .booking-change-staff-btn {
      padding: 10px;
      border-radius: 8px;
      color: #ffffff;
      background: var(--primaryColor);
      box-shadow: 0px 0px 16px 3px #959292c9; }

.booking-assign-staff-btn {
  padding: 10px;
  margin: 10px auto;
  width: 100%;
  border-radius: 8px;
  color: #ffffff;
  background: var(--primaryColor); }

.bookingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  height: calc(100% - 64px);
  row-gap: 32px;
  margin-top: auto; }
  .bookingContent > .dup-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    font-size: 28px;
    color: var(--primaryColor); }

.booking-header {
  padding: 0 1rem;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px; }
  .booking-header > .title {
    text-align: center;
    width: 100%; }
  .booking-header > .close-btn {
    position: absolute;
    right: 10px; }

.customer-details {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem; }
  .customer-details > .field {
    display: flex;
    column-gap: 16px; }
    .customer-details > .field > .title {
      font-weight: bold; }
