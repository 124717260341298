.user-groups-paper {
  height: auto; }
  .user-groups-paper > div:first-child {
    border-radius: 25px; }

.user-groups-modal {
  min-width: 500px;
  transform: none !important; }
  .user-groups-modal .user-groups-modal-content {
    margin: 0 12px; }
    .user-groups-modal .user-groups-modal-content .modal-heading-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px; }
      .user-groups-modal .user-groups-modal-content .modal-heading-container button {
        padding: 0 !important;
        margin: 0 !important;
        width: auto !important;
        height: auto !important; }
      .user-groups-modal .user-groups-modal-content .modal-heading-container h5 {
        font-weight: 200;
        font-size: 26px;
        text-align: left;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-align: center; }
    .user-groups-modal .user-groups-modal-content > hr {
      background-color: #959595;
      height: 2px !important; }

.group-tags {
  display: flex;
  overflow-x: auto; }
  .group-tags .disabled {
    cursor: not-allowed; }
    .group-tags .disabled:hover {
      opacity: 0.5; }

.table-buttons {
  justify-content: flex-end !important;
  display: flex; }
  .table-buttons .check-all {
    display: flex;
    align-items: center; }

.user-groups-modal-content .inner-container {
  margin: 15px 0 !important; }

.user-groups-modal-content .users-list-container {
  display: flex;
  flex-direction: column; }
  .user-groups-modal-content .users-list-container .users-list-total {
    color: #b8b8b8;
    font-weight: 600;
    margin: 10px 0; }
  .user-groups-modal-content .users-list-container .users-list {
    padding: 15px 30px;
    font-weight: 600; }
    .user-groups-modal-content .users-list-container .users-list .users-list-header {
      display: flex;
      justify-content: space-between;
      color: #b8b8b8;
      margin-bottom: 15px; }
    .user-groups-modal-content .users-list-container .users-list .users {
      max-height: 150px;
      overflow: auto;
      font-size: 14px; }
      .user-groups-modal-content .users-list-container .users-list .users .user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0;
        height: 32px; }
    .user-groups-modal-content .users-list-container .users-list .date {
      width: 150px; }
    .user-groups-modal-content .users-list-container .users-list .email {
      width: 150px; }

.user-groups-modal-content .group-notification span {
  padding-right: 24;
  color: #ff0000;
  font-size: 13px; }

.user-groups-modal-content .group-list-container {
  margin-bottom: 16px; }
  .user-groups-modal-content .group-list-container .group-list-total {
    color: #b8b8b8;
    font-weight: 600;
    margin: 10px 0; }
  .user-groups-modal-content .group-list-container .group-list {
    padding-top: 15px;
    font-weight: 600; }
    .user-groups-modal-content .group-list-container .group-list .group-list-header {
      display: flex;
      justify-content: space-between;
      color: #555;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 15px;
      align-items: center; }
      .user-groups-modal-content .group-list-container .group-list .group-list-header .select-all {
        display: flex;
        white-space: nowrap;
        align-items: center; }
        .user-groups-modal-content .group-list-container .group-list .group-list-header .select-all h3 {
          font-size: 10px;
          color: #b8b8b8; }
    .user-groups-modal-content .group-list-container .group-list .groups {
      max-height: 170px;
      overflow: auto; }
      .user-groups-modal-content .group-list-container .group-list .groups .group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0px 10px 25px;
        margin: 5px 0;
        border-radius: 6px;
        background-color: #f2f2f2;
        font-size: 14px;
        align-items: center; }

.user-groups-modal-content .submit-button-container {
  justify-content: center;
  display: flex;
  padding-top: 18px; }

.users-auth .empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 50px;
  border-radius: 5px; }
  .users-auth .empty-state img {
    width: 25%;
    margin: 30px; }
  .users-auth .empty-state h5 {
    font-weight: 500; }

.email-verified {
  display: flex;
  justify-content: center; }

.createAt-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: -7px; }

.adminVerified-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -7px;
  position: relative; }

.adminVerifiedMenu {
  border: 2px solid red; }

.arrowUpDown {
  cursor: pointer; }

.group-filter-btn {
  cursor: pointer;
  color: #261ed5;
  text-decoration: underline; }

.groupfiltermodal {
  background-color: #fff;
  display: grid;
  place-items: center;
  width: 50vw;
  height: 20vh; }

.groupfilterbody {
  background-color: #fff; }

.groupFilterForm {
  width: 100%; }

.groupFilterList {
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  transition: 0.2s ease; }

.groupFilterList:hover {
  font-weight: bold; }

.customModal {
  max-width: 600px !important; }

.customModalPaper {
  border: 1px solid #d4d4d4;
  border-radius: 20px !important;
  padding: 0px 20px !important; }

.customModalLink {
  color: #261ed4;
  text-decoration: none;
  cursor: pointer; }
