.mobile-overlay {
  display: none; }
  .mobile-overlay svg {
    height: auto; }

.notice-dialog {
  display: none; }

@media only screen and (max-width: 600px) {
  .mobile-overlay {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    padding: 12px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000; }
    .mobile-overlay h2,
    .mobile-overlay p {
      text-align: center; }
  .notice-dialog {
    display: none; } }
