.contact-main-container {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column; }

.contact-header {
  flex: 0.1; }

.contact-container {
  flex: 0.45;
  margin-bottom: 4rem;
  height: 33vh; }

.group-container {
  flex: 0.4;
  margin-bottom: 4rem;
  height: 33vh; }

.share-container {
  position: relative;
  z-index: 1;
  flex: 0.1;
  margin-bottom: 1rem; }
