.fk-groups-card {
    border-radius: 15px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }
  
  .fk-groups-card img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 170px;
  }
  
  .fk-groups-card-content {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 15px;
    border: 1px solid #ccc;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 10px;
  }
  
  .fk-groups-card-slots {
    position: absolute;
    top: -26px;
    left: -15px;
    background-color: #ff9211;
    color: white;
    padding: 2px 4px;
    border-radius: 8px;
    font-size: 0.9rem;
    border-bottom-left-radius: 6px;
  }
  
  .fk-groups-card-slots-border {
    position: absolute;
    height: 10px;
    top: 11px;
    left: -13px;
    border: 6px solid hsl(33deg, 100%, 46%);
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  
  .fk-groups-card-title {
    margin-bottom: 10px;
    margin-top: 4px;
  }
  
  .fk-groups-card-coach {
    margin-bottom: 0;
  }
  
  .fk-groups-card-description {
    color: #808080;
    font-size: 0.85rem;
  }
  
  .fk-groups-card-button {
    display: block;
    text-align: center;
    width: 100%;
    background: #ff9211;
    padding: 8px;
    border-radius: 50px;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  