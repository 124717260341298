.subscription-wrapper .container {
  width: 100%;
  padding: 0px 20px; }

.subscription-wrapper .items {
  display: grid;
  row-gap: 20px;
  margin: 20px 0px; }
  .subscription-wrapper .items .item {
    border: 1px solid #a2a2a2;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px; }

.subscription-wrapper a {
  text-decoration: underline;
  color: #261ed5; }
