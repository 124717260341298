.referral {
  display: flex; }
  .referral .referral-section {
    padding: 0 12px; }
  .referral .header {
    padding: 12px 0;
    margin: 24px 0;
    border-bottom: 1px solid #e2e2e2; }
  .referral .referral-container {
    width: 60%; }
  .referral .redeem-bonus-container {
    width: 40%; }
  .referral .refer-friend {
    width: 500px;
    display: block; }
  .referral .flex-row {
    flex-direction: row;
    align-items: center; }
  .referral .details {
    display: flex; }
  .referral .subscription {
    justify-content: space-around !important; }
  .referral .help-text {
    font-size: 14px;
    line-height: 19px;
    word-spacing: 2px; }
    .referral .help-text b {
      margin-left: 0.3rem;
      font-size: 14px;
      margin-right: 0.3rem; }
    .referral .help-text .more {
      padding-left: 5px; }
  .referral .mt10 {
    margin-top: 10px; }
  .referral .share-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7c12f;
    text-align: center;
    word-wrap: normal;
    white-space: normal;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    width: auto;
    min-height: 30px;
    padding: 10px;
    border-radius: 4px;
    margin: 6px 0;
    cursor: pointer; }
  .referral .copied {
    color: #35df69; }
  .referral .redeem-section {
    width: 350px;
    display: block; }
    .referral .redeem-section .reward-image {
      justify-items: center;
      text-align: center; }
      .referral .redeem-section .reward-image img {
        width: 150px; }
    .referral .redeem-section .reward-amount {
      justify-items: center;
      text-align: center; }
      .referral .redeem-section .reward-amount span {
        color: #006900;
        font-weight: 900;
        font-size: 22px; }
    .referral .redeem-section .reward-redeem-btn .outer-redeem {
      width: 40%; }
    .referral .redeem-section .reward-redeem-btn span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #006900;
      text-align: center;
      word-wrap: normal;
      white-space: normal;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      width: auto;
      min-height: 30px;
      padding: 10px;
      border-radius: 4px;
      margin: 6px 0;
      cursor: pointer;
      color: #ffffff; }
    .referral .redeem-section .reward-redeem-btn .disable-input {
      background: #ccc;
      cursor: not-allowed; }
  .referral .MuiToolbar-root button,
  .referral .MuiToolbar-root html [type='button'],
  .referral .MuiToolbar-root [type='reset'],
  .referral .MuiToolbar-root [type='submit'] {
    display: inline-block; }
  .referral .MuiTableCell-head {
    color: #261ed5; }
  .referral .MuiPaper-root {
    margin-top: 20px; }
  .referral .referral-tabs {
    padding: 0 12px; }
