.radio-menu > .tabs > div:last-child > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  max-width: 1410px;
  margin: 0 auto; }

.radio-menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 150px; }
  .radio-menu .radio-menu-item-text {
    color: #000; }

.radio-menu-item-label {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 12px 0; }

.radio-menu-item-text {
  font-size: 12px; }

.radio-menu-item-image {
  width: 100px; }

.radio-menu-item-image-container {
  width: 100%; }

.radio-menu-item-image-container > img {
  display: block;
  margin: 0 auto; }

.card-content {
  height: 80%; }

.card-actions {
  height: 20%; }
