.public-pages-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  > .public-pages {
    row-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
  }
}

.page-selector-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 90%;
  > .page-selector-all-pages {
    display: flex;
    column-gap: 1rem;
    > .public-pages {
      width: 50%;
    }
    > .private-pages {
      width: 50%;
    }
  }
}

.align-icon {
  top: 8px;
  position: relative;
}

.page-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .remove-icon-btn,
  .add-icon-btn {
    top: 5px;
    position: relative;
  }
}

.page-selector-dialog-body {
  overflow-y: hidden !important;
}
.page-selector-dialog {
  .MuiPaper-root {
    width: 60vw;
    height: 60vh;
    max-width: 1000px;
    max-height: 1000px;
  }
}

.page-list-section-title {
  width: 100%;
  padding-left: 0px;
  padding: 1rem;
  text-align: center;
  background: #f0f0f0;
}

.page-selector-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  min-height: 60px;
  > button {
    max-height: 48px;
  }
}
.pages-selector-empty-message {
  text-align: center;
}

.page-selector-loading {
  margin: 0 auto;
  display: block !important;
}

.page-selector-dialog-content {
  display: inline;
}
