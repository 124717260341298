.course-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  width: 100%; }
  .course-modal-content form {
    width: 100%; }
    .course-modal-content form > fieldset {
      width: 100%;
      border: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      margin: 0px;
      margin-top: 30px;
      margin-bottom: 30px; }
  .course-modal-content .field-wrapper {
    margin-bottom: 2rem;
    width: 90%; }
  .course-modal-content .custom-wrapper {
    margin-bottom: 24px; }
  .course-modal-content .modal-heading h5 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    text-align: center; }
  .course-modal-content .submit-button-container {
    text-align: center; }
  .course-modal-content .staffs-list {
    padding-bottom: 20px; }
  .course-modal-content .selection-disabled {
    opacity: 0.2;
    cursor: not-allowed; }
  .course-modal-content .input-info {
    display: block;
    font-size: 11px;
    top: -10px;
    margin-top: -8px;
    text-align: right;
    margin-bottom: 15px; }

.date-field-wrapper {
  position: relative;
  overflow: visible;
  width: 324px;
  max-width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto; }
  .date-field-wrapper .date-picker {
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    background-color: rgba(41, 32, 32, 0);
    color: rgba(0, 0, 0, 0.87);
    cursor: inherit;
    font: inherit;
    opacity: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    box-sizing: border-box;
    margin-top: 14px; }
    .date-field-wrapper .date-picker div {
      width: 100%;
      font-size: 12px; }
  .date-field-wrapper .filled div {
    width: 100% !important;
    font-size: 16px !important; }
  .date-field-wrapper .with-data {
    top: 10px;
    position: absolute;
    font-size: 9px; }
  .date-field-wrapper .without-data {
    top: 32px;
    position: absolute;
    display: block; }
  .date-field-wrapper label {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1;
    transform: scale(1) translate(0px, 0px);
    transform-origin: left top;
    pointer-events: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap; }

.submit-button-container {
  text-align: center; }

.button {
  border-radius: 5px;
  font-weight: 700;
  color: #ffbddb;
  padding: 10px;
  background-color: #261ed5;
  transition: all 0.4s;
  cursor: pointer; }

.button:hover {
  box-shadow: 0px 0px 20px #ffbddb; }

.error-text {
  color: red;
  margin: 3vh 0; }

.autocomplete-field fieldset {
  border: 0px; }

.autocomplete-field > label {
  left: -16px; }

.autocomplete-field > div {
  border-radius: 0px;
  padding-left: 0px !important; }
  .autocomplete-field > div input {
    padding-left: 0px !important; }

.addMoreWrapper {
  display: flex;
  justify-content: center;
  margin: 42px 0px; }
