.switch {
  position: relative;
  width: 50px;
  height: 26px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.slider:before {
  position: absolute;
  content: '';
  height: calc(50px - 50px* 3 / 5);
  width: calc(50px - 50px* 3 / 5);
  left: 7%;
  top: calc( ((26px - 2px) / 2) - ((50px - 50px* 3 / 5)) / 2);
  background-color: var(--primaryColor);
  -webkit-transition: 0.4s;
  transition: 0.4s; }

input:checked + .slider {
  background-color: transparent; }

input:checked + .slider:before {
  -webkit-transform: translateX(calc(50px* 9 / 20));
  -ms-transform: translateX(calc(50px* 9 / 20));
  transform: translateX(calc(50px* 9 / 20));
  background: var(--primaryColor); }

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
  border: 1px solid #1e2023; }

.slider.round:before {
  border-radius: 50%; }
