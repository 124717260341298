.loading-screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6; }

.loading-screen.enter {
  opacity: 0;
  transition: opacity 300ms ease-out 200ms; }

.loading-screen.loaded {
  display: none; }

.loading-widget {
  position: relative;
  bottom: 50px; }
