.edit-admin-form {
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  width: 100%;
  min-width: 500px; }
  .edit-admin-form h5 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    text-align: center; }

.restricted_access_container {
  display: flex;
  flex-direction: column; }
  .restricted_access_container > div {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .restricted_access_container > div > p {
      flex: 1; }
    .restricted_access_container > div > div {
      width: auto !important; }
