@import '../../../../../../_variables.scss';

.tooltip {
  top: 5px;
  right: 0px;
  position: absolute;
  z-index: 1000;
}

.email_input {
  width: 90%;
}

.toggleLabel {
  font-size: 14;
}

.toggle {
  margin-top: 24;
  margin-bottom: 12px;
  padding-left: 18;
  padding-right: 18;
  padding-top: 6;
  width: 90%;
}

.settingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 24px 10px 12px 0px;
}

.settingField {
  display: flex;
  width: 100%;
  position: relative;
  margin: 24px 10px 12px 0px;

  align-items: center;
  justify-content: space-between;
}

.toggleContent .field {
  width: 100%;
  margin: 24px 10px 12px 0px;
}

.settingTitle {
  border-bottom: 2px solid #c8c8c8;
  width: 100%;
  padding-bottom: 15px;
  color:$primary-color;
  font-size: 24px;
  font-family: Montserrat;
}

.settingSubtitle {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 0;
  padding: 32px 0 0 0;
  color: $primary-color;
  font-size: 20px;
  font-style: italic;
  margin-bottom: -16px;
}

.currencySelectorTitle {
  margin-right: 1em;
}

.spaceRight {
  margin-right: 85px !important;
}

.payoutSchedule {
  width: 6rem;
}

.paymentToggle {
  padding-right: 40px;
}

.settingFieldTitle {
  float: left;
  position: relative;
  display: block;
  width: fit-content;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway, sans-serif;
  font-size: 14px;
}
