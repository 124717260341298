.checkbox-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  min-height: 52px;
  padding-left: 36px;
  position: relative;
  margin-bottom: 24px;
  text-align: left; }
  .checkbox-container.invalid > span {
    border-bottom: 1px solid #f00; }
  .checkbox-container a {
    text-decoration: underline; }
