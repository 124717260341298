.modal {
  position: fixed;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  padding: 12px;
  width: 100%;
  height: 100%; }

.modal.closed {
  left: -10000px;
  transition: left 0 linear 800ms; }

.modal.open {
  left: 0px; }

.modal-content {
  padding: 12px;
  width: 100%;
  height: 100%;
  z-index: 1500;
  position: relative; }

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.modal-body {
  font-size: 14px;
  height: 100%;
  color: #414141;
  padding: 12px;
  box-sizing: border-box;
  overflow-y: auto; }

.email-padding {
  padding-left: 10px; }
