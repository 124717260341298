.button_delete {
	margin-top: 20px;
	height: 35px;
	padding: 0 40px;
	font-size: 20px;
	font-weight: bold;
	color: white;
	background-color: rgb(200, 0, 0) !important;
	border-radius: 20px;
}

.button_delete:hover {    
	box-shadow: 0px 0px 20px red;
}