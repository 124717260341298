.user-cpds-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 700px;
  color: #333; }
  .user-cpds-modal-content .modal-heading h5 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    text-align: center; }
  .user-cpds-modal-content .submit-button-container {
    text-align: center; }
  .user-cpds-modal-content .staffs-list {
    padding-bottom: 20px; }
  .user-cpds-modal-content .selection-disabled {
    opacity: 0.2;
    cursor: not-allowed; }
  .user-cpds-modal-content .input-info {
    display: block;
    font-size: 11px;
    top: -10px;
    margin-top: -8px;
    text-align: right;
    margin-bottom: 15px; }
