.promo-offer-modal {
  min-height: 450px;
  max-height: 640px;
  max-width: 770px;
  margin: 0 auto;
  display: flex;
  flex-flow: column; }
  .promo-offer-modal > div {
    padding: 20px !important; }

#offer-image,
#offer-image > img {
  height: 70%;
  width: 70%; }

#offer-content {
  margin-bottom: 1rem; }
  #offer-content > div > p {
    text-align: justify; }

#offer-content > p {
  font-weight: bolder;
  padding: 0em 1em;
  font-size: 1rem;
  text-align: justify; }
