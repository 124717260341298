.buyers-auth .empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 50px;
  border-radius: 5px; }
  .buyers-auth .empty-state img {
    width: 25%;
    margin: 30px; }
  .buyers-auth .empty-state h5 {
    font-weight: 500; }
  .buyers-auth .empty-state .subheader span {
    font-family: 'Raleway', serif; }
