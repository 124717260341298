.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px; }
  .thumbsContainer .thumb {
    position: relative;
    display: inline-flex;
    border-radius: 2;
    border: 2px solid #eaeaea;
    margin-bottom: 8px;
    margin: 10px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box; }
    .thumbsContainer .thumb .thumbInner {
      display: flex;
      min-width: 0;
      overflow: hidden; }
    .thumbsContainer .thumb .pointer {
      cursor: pointer; }
    .thumbsContainer .thumb .thumbButton {
      position: absolute;
      top: -25px;
      right: -25px;
      color: #dc004e;
      border: 0px;
      border-radius: 0.325em;
      cursor: pointer; }
      .thumbsContainer .thumb .thumbButton .icon.cog {
        height: 15px !important;
        width: 15px !important; }
    .thumbsContainer .thumb img {
      display: block;
      width: auto;
      height: 100%; }
    .thumbsContainer .thumb .preview img {
      border-width: 0;
      object-fit: cover;
      height: auto;
      max-height: 100px;
      max-width: 100px;
      vertical-align: middle;
      width: auto; }
  .thumbsContainer .thumbSelected {
    border: 2px solid #eaeeff; }

.image-dialog {
  display: flex;
  min-height: 350px;
  margin-top: 10px; }
  .image-dialog .crop-section-container {
    width: 60%; }
    .image-dialog .crop-section-container .crop-section {
      width: 400px;
      justify-items: center;
      text-align: center; }
  .image-dialog .thumbnail-section-container {
    width: 40%; }
    .image-dialog .thumbnail-section-container .thumbnail-section {
      width: 100%;
      height: 350px; }

.ReactCrop,
.ReactCrop__image,
.crop-gif-image {
  max-height: 400px;
  max-width: 400px; }

.url_upload_container {
  display: flex;
  gap: 2vw;
  margin-top: 2vh; }

.url_upload_input {
  width: 75%;
  padding: 10px 15px; }

.url_upload_button {
  font-weight: bold;
  position: relative;
  display: inline-block;
  color: #ffffff !important;
  background-color: var(--primaryColor) !important;
  cursor: pointer !important;
  flex: 1; }

.url_upload_button_file {
  font-weight: bold;
  position: relative;
  display: inline-block;
  color: #ffffff !important;
  background-color: var(--primaryColor) !important;
  cursor: pointer !important;
  padding: 20px;
  width: 100%; }

.url_upload_or_text {
  font-weight: bold; }

.file_modal_heading {
  text-align: left;
  font-weight: bold !important;
  margin-bottom: 0vh !important; }

.file_dialog_close {
  position: absolute;
  right: 0;
  top: 0px; }
