.flow-1-container {
  text-align: center; }
  .flow-1-container h3 {
    font-weight: 500; }
  .flow-1-container p {
    font-weight: 400; }

.two-list-container {
  display: flex; }
  .two-list-container ul {
    text-align: left; }
    .two-list-container ul li {
      font-size: 0.8rem;
      margin: 10px 0;
      font-weight: 300; }

.appcues-normal-flow-container h3 {
  font-weight: 500; }

.flow-2-container h3 {
  text-align: center;
  font-weight: 600; }

.flow-2-container > div {
  text-align: center; }
  .flow-2-container > div > div {
    text-align: left;
    padding: 5px; }
  .flow-2-container > div > input {
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
    width: calc(100% - 4vw);
    margin: 2vh 2vw; }

.flow-3-container {
  margin-bottom: 2vh; }
  .flow-3-container h3 {
    text-align: center;
    font-weight: 600; }
  .flow-3-container > div {
    display: flex;
    align-items: center;
    justify-content: center; }
    .flow-3-container > div > div {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .flow-3-container > div > div > input {
        visibility: hidden; }
      .flow-3-container > div > div > label {
        margin-bottom: 1vh;
        border: 1px solid #ccc;
        padding: 6px 10px;
        cursor: pointer; }
        .flow-3-container > div > div > label:nth-child(2) {
          padding: 6px 12px; }
        .flow-3-container > div > div > label:hover {
          font-weight: bold;
          color: #261ed5;
          border: 2px solid #261ed5; }

.appcues-active {
  font-weight: bold;
  color: #261ed5;
  border: 2px solid #261ed5 !important; }

.appcues-submit-btn {
  text-align: center;
  margin: 2vh auto;
  padding: 10px 20px;
  background-color: #261ed5;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none; }

.appcues-submit-btn:hover {
  box-shadow: 0px 5px 10px #261ed5;
  color: #fff;
  transform: translateY(-5px); }
