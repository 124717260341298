.date-container,
.entry-container {
  width: 100%; }

.date-container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.date-container .title,
.entry-container .title {
  align-self: flex-start;
  margin: 40px 32px 24px 32px; }

.schedule-item {
  position: relative;
  align-self: center;
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  border-radius: 3px;
  background-color: #f9f9f9; }

.extra-info-header {
  width: 290px; }

.schedule-item-divider {
  margin: 32px 0; }

.schedule-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.schedule-item-container:last-child .schedule-item-divider {
  margin-bottom: 6px; }

.remove-icon-container {
  height: 24px;
  transition: height 300ms ease-in-out;
  margin-top: 8px;
  overflow: hidden;
  text-align: right; }

.extra-info-container {
  padding-top: 30px; }

.filter-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 12px);
  border-radius: 6px;
  padding-right: 6px;
  padding-left: 12px;
  margin: 6px 12px;
  height: 48px;
  background-color: #f6f6f6; }

.filter-item .design-color {
  width: auto; }

.filter-label {
  flex: 1;
  font-size: 12px;
  margin-right: 12px;
  padding-left: 12px; }

.hidden {
  display: none; }
