.sms-bundle-title {
  color: #333;
  margin: 16px 0;
  font-size: 21px;
  font-weight: 400;
  text-transform: uppercase; }

.sms-price-container {
  margin: 42px 0; }

.sms-bundle-subtitle {
  text-align: center;
  color: #333; }

.sms-bundle-price {
  color: #333;
  margin: 0;
  font-weight: 800;
  font-size: 38px; }
