.selectable-card {
  position: relative;
  margin: 6px;
  padding: 24px;
  max-width: 260px;
  background-color: transparent;
  border-radius: 6px;
  text-align: center;
  border: 2px dashed #fff;
  transition: all 300ms linear; }
  .selectable-card > div {
    border-radius: inherit; }
  .selectable-card > div:before {
    background-image: linear-gradient(-20deg, var(--primaryColor), #eaeeff);
    border-radius: inherit;
    bottom: -2px;
    content: '';
    left: -2px;
    opacity: 0;
    position: absolute;
    right: -2px;
    top: -2px;
    transition: opacity 0.4s ease;
    z-index: -1; }
  .selectable-card:hover, .selectable-card:focus {
    border: 2px dashed #f2f2f2; }
  .selectable-card.box-shadow-on-hover:hover, .selectable-card.box-shadow-on-hover:focus {
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.1) 0px 1px 4px; }
  .selectable-card.highlight {
    background: #e1dede; }
  .selectable-card.active {
    border: 2px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    transition: 0;
    color: #ffffff; }
    .selectable-card.active > div:before {
      opacity: 1; }
  .selectable-card.disabled:hover {
    cursor: pointer; }
  .selectable-card.highlight-border {
    border: 2px dashed #eaeeff; }
  .selectable-card.not-selectable {
    cursor: default; }

.selectable-card.disabled {
  border-style: dashed;
  cursor: default;
  position: relative; }
  .selectable-card.disabled:hover, .selectable-card.disabled:focus {
    background-clip: border-box;
    background-color: #f1f1f1;
    box-shadow: none;
    border: 2px #e1dede dashed; }
  .selectable-card.disabled:hover > div,
  .selectable-card.disabled:hover .disabled-notice, .selectable-card.disabled:focus > div,
  .selectable-card.disabled:focus .disabled-notice, .selectable-card.disabled:active > div,
  .selectable-card.disabled:active .disabled-notice {
    opacity: 1; }
  .selectable-card.disabled > div {
    opacity: 0.3;
    transition: opacity 300ms linear; }
  .selectable-card.disabled.coming-soon.coming-soon > div {
    opacity: 0.9; }
  .selectable-card.disabled .coming-soon {
    height: 120px;
    left: 50%;
    margin: -60px;
    position: absolute;
    top: 74px;
    width: 120px; }
  .selectable-card.disabled .disabled-icon {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 32px;
    z-index: 3; }
    .selectable-card.disabled .disabled-icon img {
      width: 32px; }
  .selectable-card.disabled .disabled-notice {
    align-items: center;
    background: rgba(241, 241, 241, 0.8);
    bottom: 0;
    display: flex;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 16px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.4s ease;
    z-index: 2; }
    .selectable-card.disabled .disabled-notice .disabled-notice-message {
      background: #fff;
      width: 100%;
      border-radius: 3px;
      font-size: 12px;
      line-height: 30px;
      margin: 10px 0;
      text-align: center;
      text-transform: uppercase;
      padding: 0 16px; }

.create-new-component .selectable-card.disabled .disabled-notice span {
  background: #ffffff;
  border: none;
  border-radius: 3px;
  display: inline-block;
  font-size: 11px;
  line-height: 20px;
  margin: 0;
  text-align: center;
  text-transform: none;
  padding: 0; }
