.auth-panel {
  background-color: #fff;
  border-radius: 6px;
  width: 100%;
  padding: 20px; }

.custom-field {
  line-height: 24px;
  left: -36px;
  position: relative; }
  .custom-field button {
    display: inline-flex !important;
    vertical-align: middle;
    padding: 0px !important;
    margin-right: 10px; }

.auth-container {
  margin-top: 10px;
  height: 100%;
  overflow: scroll; }

.template-text {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 690px;
  word-break: break-word;
  white-space: pre-wrap;
  width: 100%; }

.auth-divider {
  height: 50px;
  display: flex;
  flex-direction: row; }

.auth-divider-line {
  height: 50%;
  width: 40%;
  border-bottom: 2px solid #c8c8c8;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0; }

.auth-panel-title {
  border-bottom: 2px solid #c8c8c8;
  width: 100%;
  padding-bottom: 15px;
  color: var(--primaryColor);
  font-size: 24px;
  font-family: Montserrat; }

.auth-panel-subTitle {
  width: fit-content;
  margin-left: 16px;
  padding: 16px 0;
  color: var(--primaryColor);
  font-size: 16px;
  border-bottom: 2px solid #c8c8c8;
  font-family: Montserrat; }

.auth-panel-register {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.auth-type {
  display: flex;
  justify-content: space-evenly;
  width: 100%; }

.auth-type-button {
  flex-grow: 1;
  margin: 10px; }

.auth-panel-privacy-url-textbox {
  width: 100% !important; }

.notification-admin {
  text-align: right; }

.notification-admin a {
  font-size: 0.8rem;
  text-decoration: underline;
  color: var(--primaryColor);
  font-weight: 600; }

.header-Wrapper {
  margin-bottom: 10px; }

.btnField {
  color: #261ed5 !important;
  text-decoration-color: rgba(38, 30, 213, 0.4) !important; }

.btnField span {
  color: #261ed5; }

.paymentMethod {
  margin-top: 36px;
  margin-bottom: 20px; }
