.icon {
  position: relative;
  display: inline-block;
  color: #414141;
  fill: currentcolor; }

.icon.settings-icon,
.icon.user-icon {
  color: var(--primaryColor);
  height: 32px;
  width: 32px; }

.icon.groups-icon {
  color: var(--primaryColor);
  height: 36px;
  width: 36px; }

.empty-icon {
  width: 200px;
  height: 200px; }

.push-icon {
  width: 34px;
  height: 34px; }
