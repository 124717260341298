.page-header {
  background-color: #ffffff;
  height: 100px;
  z-index: 1;
  margin-bottom: 10px; }
  .page-header .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid #1e2023; }
  .page-header .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto; }
  .page-header .page-title {
    display: inline-block;
    text-transform: capitalize;
    color: var(--primaryColor);
    font-weight: 800;
    font-size: 24px;
    margin: 0;
    padding-right: 24px;
    line-height: 73px; }
  .page-header .lightening-logo {
    display: inline-block;
    width: 15px;
    margin: 0 15px;
    color: var(--primaryColor); }
