.panel {
  flex: 1;
  height: 100%;
  min-height: 500px;
  border: 1px solid #f8f8f8;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.01); }

.panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
  line-height: 42px;
  background-color: #f0f0f1;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 12px;
  position: relative; }

.panel-title-container {
  display: flex;
  align-items: center; }

.panel-header-title {
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: #545454;
  margin: 0 12px;
  line-height: normal; }

.panel-scroll-area {
  height: 100%;
  min-height: 500px;
  background-color: #fff;
  border-radius: 6px;
  overflow-y: auto; }
  .panel-scroll-area.title {
    height: calc(100% - 60px); }

.panel-scroll-area.padded {
  padding: 1rem 2rem; }

.panel-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
  line-height: 42px;
  background-color: #e7b790;
  padding: 0 12px;
  position: relative; }

.panel-toolbar-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

@media (max-height: 675px) {
  .notification-panel .panel {
    position: static;
    height: 75vh;
    min-height: 0px; }
    .notification-panel .panel .panel-scroll-area {
      min-height: 0px; }
      .notification-panel .panel .panel-scroll-area .form-container {
        padding-bottom: 12px; }
      .notification-panel .panel .panel-scroll-area .notification-wrapper {
        position: static; } }
