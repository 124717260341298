.underline-text {
  text-decoration: underline; }

.underline-text:hover {
  color: rgba(0, 0, 0, 0.5); }

.form-container {
  padding-bottom: 0; }

.user-signup-button button#user-signup > div > span {
  padding: 11px 55px !important; }

.user-signup-button button#user-signup > div > div > span {
  padding: 11px 55px !important; }

.captcha-box {
  margin: 12px 0; }
