@import '../../../../../../_variables';
.container_top {
  padding: 150px 25px 40px 25px !important;
  color: #333;
  text-align: center;
}

.container_top h1 {
  font-size: 4rem;
  color: $primary-color;
}

.container_top p {
  font-size: 1.2rem;
  margin-bottom: 25px;
}

.container_middle {
  padding: 20px 100px !important;
  color: $main-black;
  text-align: center;
  background-color: $main-white;
}

.container_middle h1 {
  font-size: 2rem;
  text-transform: capitalize;
}

.container_middle p {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.container_bottom {
  padding: 25px 40px !important;
  color: #333;
}

.card {
  display: flex;
  margin-bottom: 80px;
}

.container_bottom h3 {
  font-size: 2rem;
}

.container_bottom p {
  font-size: 1.2rem;
}

.image {
  max-width: 50%;
  margin-left: 80px;
  align-self: center;
}

.card_reverse {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 80px;
  text-align: left;
}

.card_reverse div {
  text-align: right;
}

.card_reverse .image {
  margin-left: unset;
  margin-right: 80px;
}

.card_reverse h3, .card_reverse p{
  text-align: left;
}

.container_appshell {
  padding: 25px 100px !important;
  color: $main-black;
  text-align: center;
  background-color: $main-white;
}

.container_appshell h1 {
  font-size: 2rem;
  text-transform: capitalize;
}

.container_appshell p {
  font-size: 1.2rem;
  margin-bottom: 25px;
}

.container_appshell a {
  display: flex;
  margin-top: 40px;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 30px;
  justify-content: center;
  align-self: center;
  border-radius: 50px;
  background-color: $primary-color;
  font-family: Gibson, sans-serif;
  color: $primary-text-color;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  border: 3px solid $primary-color;
  transition: all 0.3s;
}

.container_appshell a:hover {
  background: none;
  color: $primary-color;
  transition: all 0.3s;
}

.container_appshell div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 60px 0;
}

.container_appshell div img {
  max-width: 100%;
}
