.menu-tabs {
  border-bottom: 2px solid #e1dede; }

.tabs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  padding-left: 28px;
  padding-right: 12px;
  background-color: #e1dede; }

.tabs-header-title {
  flex: 1;
  text-transform: uppercase;
  font-size: 12px; }

.view-item,
.tab-item {
  position: relative !important;
  height: 60px;
  line-height: 60px;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  transition: all 500ms ease-in-out; }

.view-item.fade-in {
  animation: fadeIn 300ms normal forwards ease-in-out; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  20% {
    transform: translateY(-30px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

.tab-item {
  padding-left: 36px !important; }

.item-validation {
  display: inline-block;
  margin: 0 10px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: var(--primaryColor);
  /*rgb(224, 55, 63);*/ }

.view-item.active,
.tab-item.active .nested-page.active {
  border-right: 2px solid #eaeeff !important; }

.view-item.active,
.nested-page.active {
  background-color: #eaeeff !important; }

.tab-item.active {
  background: linear-gradient(-90deg, #ffffff, #eaeeff) !important; }

.view-item:hover,
.view-item:focus,
.tab-item:hover,
.tab-item:focus {
  background-color: #eaeeff !important; }

.view-item .hover-icon-button {
  opacity: 0;
  transition: opacity 300ms ease-out; }

.view-item .icon-right {
  padding-right: 20px; }

.view-item.active .hover-icon-button,
.view-item:hover .hover-icon-button,
.view-item:focus .hover-icon-button,
.hover-icon-button:focus {
  opacity: 1 !important; }

.view-item-inner-div,
.tab-item-inner-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

.view-item-label,
.tab-item-label {
  flex: 1;
  font-size: 13px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  color: var(--primaryColor); }

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  line-height: 24px; }

.button-group.padded {
  padding-left: 30px; }
