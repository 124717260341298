.cc-form-container {
  width: 100%;
  margin-bottom: 24px;
  text-align: center; }

.cc-form {
  margin: 24px auto;
  width: 320px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.cvc-expiry {
  display: flex;
  flex-direction: row;
  justify-content: space-around; }

.card-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.card-form > .card-number-container,
.card-form > .card-cvc-expiry-container {
  width: 50%;
  min-width: 400px;
  display: flex;
  margin: 1em 0;
  justify-content: space-between; }

.card-form > .card-number-container > label,
.card-form > .card-number-container > p,
.card-form > .card-cvc-expiry-container > .card-expiry-container > label,
.card-form > .card-cvc-expiry-container > .card-expiry-container > p,
.card-form > .card-cvc-expiry-container > .card-cvc-container > label,
.card-form > .card-cvc-expiry-container > .card-cvc-container > p {
  align-self: flex-start;
  margin: 0px; }

.card-form > .card-number-container > .StripeElement,
.card-form > .card-cvc-expiry-container > .StripeElement {
  width: 50%; }

.card-expiry-container,
.card-cvc-container,
.card-number-container {
  display: flex;
  flex-direction: column; }
