.beezer-modal {
  min-height: 450px; }

.beezer-medium-modal {
  min-height: 250px !important;
  display: flex;
  align-items: center; }

#offer-image,
#offer-image > img {
  height: 70%;
  width: 70%; }

#offer-content {
  margin-bottom: 3rem; }

#offer-content > p {
  font-weight: bolder;
  padding: 0em 1em;
  font-size: 1rem;
  text-align: justify; }
