#main {
  display: flex;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-left: 109px;
  height: 100%;
  width: calc(100% - 109px);
  background-color: #f8f8f8; }

#sidebar {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 501; }

.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 502;
  height: 100%; }
  .main-menu .logo {
    padding: 10px 18px;
    margin: 25px 0; }
  .main-menu .main-menu-list {
    display: flex;
    flex-direction: column; }

.main-menu > div {
  display: flex;
  flex-direction: column; }

.route-enter {
  opacity: 0.01;
  transform: translate3d(0, 80px, 0); }

.route-enter.route-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 300ms ease-in, opacity 300ms ease-in; }

.route-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.route-leave.route-leave-active {
  opacity: 0.01;
  transform: translate3d(0, 80px, 0);
  transition: transform 300ms ease-in, opacity 300ms ease-in; }

@media (min-width: 1101px) {
  #main {
    margin-left: 142px;
    width: calc(100% - 142px); }
  .main-menu {
    width: 142px; } }

@media (max-width: 1101px) {
  .main-menu {
    width: 142px; } }
