.icon {
  position: relative;
  display: inline-block;
  color: #ffffff;
  fill: currentcolor;
  height: 24px;
  width: 24px; }

.icon.undo {
  height: 18px;
  width: 18px; }

.icon.delete {
  bottom: 1px;
  height: 17px;
  width: 17px; }

.icon.undo {
  right: 6px; }

.icon.dashboard,
.icon.distribute,
.icon.engage,
.icon.memberLogin,
.icon.analytics,
.icon.builder,
.icon.subscribe .icon.ecommerce {
  height: 35px;
  width: 35px;
  color: #ffffff; }

.icon.builder {
  position: relative;
  left: 3px; }

.icon.empty-icon .cls-1 {
  fill: none; }

.icon.empty-icon .cls-2 {
  fill: var(--primaryColor); }

.icon.drag {
  height: 17px;
  width: 17px; }

.icon.view,
.icon.tabview,
.icon.homepage {
  color: rgba(65, 65, 65, 0.75);
  bottom: 1px;
  height: 15px;
  width: 15px; }

.icon.pencil {
  height: 34px;
  width: 34px;
  right: 6px; }

.icon.create,
.icon.close {
  height: 20px;
  width: 20px; }

.icon.back {
  height: 19px;
  width: 19px;
  color: var(--primaryColor); }
