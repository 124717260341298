.ml-intro-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 48px;
  text-align: center; }
  .ml-intro-content img {
    width: 100px;
    height: 100px;
    margin-bottom: 2rem; }
