.profile-form-container {
  max-height: calc(100vh - 211px);
  overflow-y: auto; }

.profile-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  min-width: 700px; }

.profile-fieldset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; }

.profile-fieldset > div {
  padding: 0 12px;
  min-height: 272px; }

.legal-links-container {
  font-size: 12px;
  display: flex;
  flex-direction: row; }
