.form-container .section-header {
  padding-top: 8px; }

.form-container hr {
  width: 100%;
  height: 2px; }

.form-container .text-area-container {
  margin: 24px 0; }
  .form-container .text-area-container .text-area {
    min-height: 80px; }

.form-container .select-field-wrapper {
  margin-top: 0; }
