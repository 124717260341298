.pn-category:before {
  content: '';
  width: 12px;
  height: 12px;
  display: inline-block; }

.pn-category.all:before {
  background-color: #6DCFF6; }

.link {
  color: revert;
  text-decoration: revert; }
