.staff-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 700px; }
  .staff-modal-content .field-wrapper {
    margin: 1rem;
    width: 50%; }
  .staff-modal-content .dialog-label {
    font-weight: 600;
    color: var(--primaryColor); }
