.text-field-wrapper {
  position: relative;
  overflow: visible;
  max-width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto; }

.input-action-group {
  position: relative;
  display: flex;
  flex: 1; }

.input-action {
  position: absolute;
  right: -6px;
  bottom: 7px; }

.right-input-action {
  display: flex;
  height: 48px;
  width: 48px;
  position: absolute;
  bottom: 2px;
  right: 0;
  justify-content: center;
  align-items: center; }

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1300;
  background: #fff;
  max-height: 151px;
  width: 100%;
  overflow: auto;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.suggestion-item {
  cursor: pointer;
  display: flex;
  outline: 0;
  min-height: auto;
  align-items: center;
  padding-top: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6px;
  justify-content: flex-start;
  -webkit-tap-highlight-color: transparent; }

.suggestion-item--active {
  cursor: pointer;
  display: flex;
  outline: 0;
  min-height: auto;
  align-items: center;
  padding-top: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6px;
  justify-content: flex-start;
  -webkit-tap-highlight-color: transparent;
  background-color: rgba(0, 0, 0, 0.14); }
