.date-field-wrapper {
  position: relative;
  overflow: visible;
  width: 324px;
  max-width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto; }
  .date-field-wrapper .date-picker {
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    background-color: rgba(41, 32, 32, 0);
    color: rgba(0, 0, 0, 0.87);
    cursor: inherit;
    font: inherit;
    opacity: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    box-sizing: border-box;
    margin-top: 14px; }
    .date-field-wrapper .date-picker div {
      width: 100% !important;
      color: #333 !important;
      font-family: 'Raleway', 'Karla', sans-serif;
      font-weight: 400;
      font-size: 1rem !important;
      line-height: 1.4375em;
      letter-spacing: 0.00938em; }
  .date-field-wrapper .filled div {
    width: 100% !important;
    font-size: 16px !important; }
  .date-field-wrapper .with-data {
    top: 10px;
    position: absolute;
    font-size: 9px; }
  .date-field-wrapper .without-data {
    top: 32px;
    position: absolute;
    display: block; }
  .date-field-wrapper label {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1;
    transform: scale(1) translate(0px, 0px);
    transform-origin: left top;
    pointer-events: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap; }

.item-location label {
  font-size: 12px; }

.dropzone-wrapper {
  text-align: left;
  margin-top: 33px;
  margin-bottom: -14px;
  position: relative; }
  .dropzone-wrapper .dropzone-label {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1;
    transform: scale(1) translate(0px, 0px);
    transform-origin: left top;
    pointer-events: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    white-space: nowrap;
    text-align: left;
    position: absolute;
    margin-left: 1px;
    margin-top: 3px; }

.hintText {
  font-size: 12px;
  padding-left: 8px;
  display: inline-block;
  margin-top: 13px;
  color: #ff0000; }

.custom-select-field {
  width: 324px; }
