.reset-email {
  max-height: calc(100vh - 211px);
  overflow-y: auto; }
  .reset-email > div {
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .reset-email .error-message {
    color: #df2d2d; }
  .reset-email .success-message {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--primaryColor);
    height: 30px; }
