.social-links-container {
  width: 100%;
  height: 80px; }

.social-links-icons-list {
  left: -31415px;
  height: 116px;
  width: 236px;
  display: flex;
  align-items: center; }

.social-link-text-field {
  display: flex;
  align-items: center; }

.social-section-header {
  padding: 20px 0 20px 18px; }

.googlemap-style {
  margin-left: -5px; }
