.productContainer {
  width: 100%;
  height: calc(100vh - 85px);
  display: flex;
  padding: 16px;
  column-gap: 1rem; }

.productImage {
  width: 50%;
  height: 100%; }

.productDetails {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 50%;
  height: 100%;
  padding: 1rem; }
  .productDetails .fields {
    display: flex;
    column-gap: 1rem;
    align-items: center; }
    .productDetails .fields .name {
      font-weight: bold; }
  .productDetails .productDescription {
    align-items: flex-start;
    flex-direction: column; }
  .productDetails .action-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    justify-content: center; }

.productRejectButton {
  background-color: #d32f2f !important;
  color: #ffffff !important;
  width: 100%;
  max-width: 250px; }

.productAcceptButton {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  width: 100%;
  max-width: 250px; }

.disabledButton {
  background-color: #e1dede !important;
  color: #1e2023 !important;
  cursor: not-allowed !important; }

.seller-link {
  text-decoration: revert;
  color: revert; }

.approve-vendor-msg {
  text-align: center;
  color: #d32f2f; }
