.form-detail-paper div:first-child {
  border-radius: 25px !important; }

.form-modal-content .date {
  display: block; }

.form-modal-content .label {
  color: var(--primaryColor);
  display: block;
  margin: 30px 0 20px;
  font-size: 14px; }

.form-modal-content .value {
  color: black;
  font-size: 14px;
  overflow: auto;
  max-height: 50px;
  display: inline-block;
  word-break: break-all; }

.form-modal-content .fields {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto; }
  .form-modal-content .fields .field {
    width: 50%; }
    .form-modal-content .fields .field:nth-child(odd) {
      padding-right: 15px; }
    .form-modal-content .fields .field:nth-child(even) {
      padding-left: 15px; }
