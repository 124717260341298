.pn-category:before {
  content: '';
  width: 12px;
  height: 12px;
  display: inline-block;
  border: 2px solid red; }

.pn-category.all:before {
  background-color: #6dcff6; }

.table-row .selected {
  justify-content: flex-end;
  display: flex; }

.table-row .scheduled-button {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .table-row .scheduled-button button {
    padding: 0 !important;
    width: 24px !important;
    height: 24px !important; }
    .table-row .scheduled-button button svg {
      color: #a7a9ac !important;
      fill: #a7a9ac !important; }

.sentToTable > ul > li > .MuiTreeItem-content {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin: 0;
  padding: 0; }

.sentToTable > ul > li > .MuiTreeItem-content > .MuiTreeItem-iconContainer {
  margin-left: 4px; }

.sentToTable > ul > li > .MuiTreeItem-content > .MuiTreeItem-label {
  padding-left: 0px !important;
  font-size: 16px !important; }

.sentToTable > ul > li > .Mui-selected {
  background-color: transparent !important; }

.sentToTable
> ul
> li
> ul.MuiTreeItem-group
> .MuiCollapse-wrapper
> .MuiCollapse-wrapperInner
> h3 {
  font-size: 16px; }
