.pgmol-modal-main-container .text-field-wrapper {
  padding-left: 0px;
  margin: 0; }

.pgmol-modal-main-container .text-area-container {
  margin-left: 0; }

.pgmol-modal-main-container .MuiSnackbar-root {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .pgmol-modal-main-container .MuiSnackbar-root .MuiPaper-root {
    background-color: #f44336 !important;
    color: #fff !important; }

.pgmol-modal-regional-main-container > div > div {
  width: 100% !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
  margin-left: 9% !important; }

.pgmol-image-container {
  height: 35vh;
  display: flex;
  flex-wrap: no-wrap;
  gap: 2vw;
  overflow-x: scroll;
  width: 205%;
  margin-bottom: 2vh;
  padding-bottom: 2vh; }

.pgmol-image-container {
  display: flex;
  overflow-x: auto;
  height: 30vh;
  margin-top: 3vh; }

.image-container {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 0px;
  /* Adjust the spacing between images */
  flex-shrink: 0;
  /* Prevent images from shrinking to fit */ }

.image-container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 5px; }

.delete-image-icon {
  position: absolute;
  top: 5px;
  right: 5px; }

.delete-image-icon > button {
  color: red;
  font-size: 1.5rem; }

.delete-image-icon > button > svg {
  font-size: 1.5rem !important;
  border: 2px solid red !important;
  border-radius: 50%;
  background-color: #fff; }

.regional-performance-tabs-container {
  width: 200%;
  margin: 2vh 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden; }

.pgmol_contacts_fields_container {
  display: flex;
  padding: 10px; }
  .pgmol_contacts_fields_container .text-field-wrapper {
    width: 350px !important; }

.pgmol-regional-services-label {
  font-size: 0.95rem !important;
  color: #000;
  font-weight: 500;
  margin-bottom: 0; }

.pgmol-regional-services-label::after {
  content: '*';
  color: red;
  font-size: 25px;
  display: inline; }

.pgmol_contacts_fields_container > div:nth-child(2) {
  margin-left: 10px; }

.pgmol_contacts_fields_timetable {
  display: flex;
  justify-content: flex-start; }
  .pgmol_contacts_fields_timetable > .text-area-container {
    margin-left: 10px; }

.pgmol-modal-heading {
  display: flex;
  text-align: center;
  gap: 2vw;
  margin-bottom: 2vh;
  width: 100%;
  position: relative; }
  .pgmol-modal-heading > h5 {
    font-weight: bolder !important; }

.pgmol-heading-close-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
  color: #291fd6; }

.regional-table-text-left {
  text-align: justify !important;
  justify-content: flex-start !important; }

.pgmol-label {
  font-size: 0.95rem;
  color: #000;
  font-weight: 500;
  margin-bottom: 0; }

#contact_1_phone::-webkit-inner-spin-button,
#contact_1_phone::-webkit-outer-spin-button,
#contact_2_phone::-webkit-inner-spin-button,
#contact_2_phone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.pgmol-table-container .table {
  height: 80vh; }

.pgmol-table-container .table-header {
  display: flex;
  align-items: flex-start;
  padding-top: 28px !important; }

.pgmol-table-container .table-row {
  display: flex;
  align-items: flex-start;
  padding-top: 10px !important;
  padding-bottom: 5px !important; }

.pgmol-table-container pre {
  margin: 0; }

.pgmol-auto-complete .MuiFormHelperText-root {
  color: red !important;
  font-size: 0.8rem;
  font-family: 'Raleway', 'Karla', sans-serif !important;
  letter-spacing: 0px; }

.pgmol-auto-complete .Mui-error::after {
  border-bottom-color: red !important;
  border-bottom: 1px solid red !important; }

.pgmol-auto-complete .MuiAutocomplete-endAdornment {
  border: 2px solid red !important;
  display: none !important; }

.pgmol-regional-service-label {
  color: red !important;
  font-weight: bold !important; }
