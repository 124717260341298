.tab {
  padding: 24px;
  border-top: 1px solid #c4c4c4; }

.account-topbar {
  position: relative;
  height: 50px;
  padding: 12px; }

.close-btn {
  position: absolute;
  right: 12px; }

.thing {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  padding: 12px 36px;
  border-radius: 4px;
  background-color: #f6f6f6; }

.thing .details {
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left; }

.thing .border-right {
  background-color: #f6f6f6; }

.thing .flex-row {
  flex-direction: row;
  align-items: center; }

.thing .title {
  display: block;
  margin: 6px 0;
  padding: 0; }

.thing .subtitle {
  display: block;
  margin: 3px 0; }

.thing .app-link {
  display: block;
  margin: 6px 0;
  text-decoration: underline; }

.align-right {
  align-items: flex-end !important; }

.margin-right {
  margin-right: 24px; }
