.history-detail-paper div:first-child {
  border-radius: 25px !important; }

.history-modal-content .history-modal-content-title {
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px; }

.history-modal-content .date {
  display: block; }

.history-modal-content .label {
  color: var(--primaryColor);
  display: block;
  margin: 30px 0 20px;
  font-size: 14px; }

.history-modal-content .value {
  color: black;
  font-size: 14px;
  overflow: auto;
  max-height: 50px;
  display: inline-block;
  word-break: break-all; }

.history-modal-content .fields {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto; }
  .history-modal-content .fields .field {
    width: 50%; }
    .history-modal-content .fields .field:nth-child(odd) {
      padding-right: 15px; }
    .history-modal-content .fields .field:nth-child(even) {
      padding-left: 15px; }

.history-modal-content .charts-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
  min-height: 200px; }
  .history-modal-content .charts-container .chart-container {
    min-width: 400px; }
    .history-modal-content .charts-container .chart-container .chart-title {
      font-weight: 600;
      text-align: center;
      margin-bottom: 15px; }
  .history-modal-content .charts-container .chart-controls {
    display: inline;
    z-index: 999;
    cursor: pointer;
    max-width: 50px; }
    .history-modal-content .charts-container .chart-controls:disabled {
      cursor: not-allowed; }

.history-modal-content .empty-chart-state {
  text-align: center;
  width: 400px; }

.history-modal-content .pushImageWrap {
  max-width: 85px; }

.filteredList {
  max-height: 20vh;
  height: auto;
  overflow-y: scroll;
  margin-top: -2vh; }

.itemList {
  margin-top: -5vh;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px; }
  .itemList h5 {
    margin-bottom: 7px;
    margin-top: 0px; }
