.bubble-btn-container {
  position: relative;
  display: inline-block;
  padding: 6px;
  margin: 12px;
  border-radius: 38px;
  box-shadow: rgba(255, 255, 255, 0.2) 0 0 0 12px, rgba(255, 255, 255, 0.4) 0 0 0 6px; }
  .bubble-btn-container .bubble-btn-label {
    font-size: 0.85em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #555555;
    padding-left: 16px;
    padding-right: 16px;
    text-transform: uppercase; }
  .bubble-btn-container .outer,
  .bubble-btn-container .inner {
    display: inline-block;
    padding: 6px;
    border-radius: 30px; }
  .bubble-btn-container.disabled {
    opacity: 0.75; }
