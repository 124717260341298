.h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 35px;
}

.appoval_status_buttons {
  margin-top: 35px;
}

.appoval_status_buttons span {
  gap: 10px;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

.approve {
  background-color: green;
}

.disapprove {
  background-color: red;
}

.appoval_status_buttons p {
  text-align: center;
  margin-top: 20px;
}

.save {
  width: 100%;
  margin-top: 30px;
  border-radius: 5px;
  font-weight: 700;
  color: #ffbddb;
  padding: 10px;
  background-color: #261ed5 !important;
  transition: all 0.4s;
  cursor: pointer;
}
