.visit-link-mobile {
  display: none; }

@media only screen and (max-width: 600px) {
  .offer-illustration {
    display: none; }
  h4 {
    margin: 12px !important;
    margin-top: 24px !important;
    margin-bottom: -12px !important; }
  .visit-link-desktop {
    display: none; }
  .visit-link-mobile {
    display: block; } }
