.vendors-auth .empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 50px;
  border-radius: 5px; }
  .vendors-auth .empty-state img {
    width: 25%;
    margin: 30px; }
  .vendors-auth .empty-state h5 {
    font-weight: 500; }

.product-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; }
