.item-container {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #F8F8F8;
  margin: 24px 6px;
  overflow: hidden; }

.item-container-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 42px;
  line-height: 42px;
  background-color: #F0F0F1;
  padding: 0 12px; }

.item-toolbar-actions {
  display: flex;
  flex-direction: row; }

.item-container-title {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  margin: 0 6px; }
