.component-item {
  transition: all 300ms ease-out; }

.component-item:hover,
.component-item:focus {
  background-color: #f0f0f0 !important;
  outline: none; }

.component-item-inner-div {
  display: flex;
  height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding-right: 24px; }

.component-item-label {
  flex: 1;
  font-size: 14px;
  margin-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primaryColor);
  padding-left: 5px; }

.component-item-right-icon {
  padding: 6px 12px; }

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 11px;
  line-height: 24px;
  margin-right: 8px; }
