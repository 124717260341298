@import '../../../../variables';
.button {
  border-radius: 5px;
  font-weight: 700;
  color: $primary-text-color;
  padding: 10px;
  background-color: $primary-color;
  transition: all 0.4s;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 0px 20px $primary-color-light;
}

.capitilize {
  text-transform: capitalize;
}

.dateModal {
  display: grid;
  place-items: center;
}

.modalContainer {
  background-color: #fff;
  width: 80vw;
  height: 43vh;
  display: flex;
  align-items: center;
}

.modalLeft {
  margin-left: 6vw;
  height: 100%;
}
.modalRight {
  margin-left: 6vw;
  height: 100%;
}

.filterBtn {
  height: 5vh;
}

