.account-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    > div:first-child {
      background-color: #fff !important;
    }
  }
}
