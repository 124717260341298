.legal,
.legal p,
.legal li,
.legal td {
  font-size: 14px; }

.legal h1 {
  text-align: center;
  font-size: 2rem;
  margin: 3rem 0; }

.legal h2 {
  text-transform: uppercase; }

.legal li {
  margin: 12px 0; }

.legal .definitions {
  border-collapse: separate;
  border-spacing: 1rem; }
  .legal .definitions td:first-child {
    font-weight: 600;
    font-size: 15px;
    min-width: 120px; }

.legal .cookies {
  border-collapse: separate;
  border-spacing: 4rem 2rem;
  text-align: left; }
  .legal .cookies th {
    font-weight: 600; }

.legal .sub-clause {
  list-style-type: none; }

.legal .sub-clause-item {
  display: flex; }
  .legal .sub-clause-item span:nth-child(1) {
    margin-right: 10px; }

.agreement-label {
  font-size: 12px; }
  .agreement-label .label-link {
    position: relative;
    text-decoration: underline;
    z-index: 999; }

.legal {
  z-index: 2000; }
