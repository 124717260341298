.app-link-menu-wrapper {
  display: block;
  width: 100%;
  padding-top: 30px; }
  .app-link-menu-wrapper .app-link-container {
    display: flex;
    padding: 0 1.5rem;
    align-items: stretch;
    justify-items: stretch;
    padding: 0 1.5rem; }
    .app-link-menu-wrapper .app-link-container .link-radio-option-wrapper {
      display: inline-block;
      width: 278px; }
      .app-link-menu-wrapper .app-link-container .link-radio-option-wrapper .select-field-wrapper {
        width: 100%;
        padding-left: 6px;
        padding-right: 6px;
        margin: 0; }
    .app-link-menu-wrapper .app-link-container .radio-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: space-around; }
      .app-link-menu-wrapper .app-link-container .radio-wrapper > div {
        top: 10px; }
