.visit-link-mobile {
  display: none; }

.social-icon {
  width: 50px;
  height: 50px;
  fill: var(--primaryColor);
  margin: 20px auto; }

.content {
  padding: 20px 60px;
  text-align: center; }

.content > p {
  font-size: 1.3rem; }

@media only screen and (max-width: 600px) {
  .register-illustration {
    display: none; }
  h4 {
    margin: 12px !important;
    margin-top: 24px !important;
    margin-bottom: -12px !important; }
  .visit-link-desktop {
    display: none; }
  .visit-link-mobile {
    display: block; } }
