@import '../../../../../../../variables';

.diy-dialog-content {
  text-align: center;
  color: $main-black;
}

.search-input-wrapper--container {
  width: 80%;
}

.diy-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
