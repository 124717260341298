.list-item {
  display: block;
  font-size: 13px;
  height: 65px;
  width: 100%;
  margin: 6px 0;
  background-color: #f8f8f8;
  border-radius: 3px;
  position: relative; }

.list-item:first-child {
  margin-top: 0; }

.list-item.interactive {
  background-color: #f8f8f8;
  transition: background-color 300ms ease-in-out;
  cursor: pointer; }

.list-item.interactive:hover,
.list-item.interactive:focus {
  background-color: #f0f0f0;
  outline: none; }

.list-item-inner-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 65px;
  width: 100%;
  padding: 0 18px; }

.notification-dial {
  height: 10px;
  width: 10px;
  background-color: #8b24fc;
  border-radius: 100%;
  margin: 0 16px; }

.list-item-title {
  color: #333;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px; }

.list-item-subtitle {
  margin-top: 3px;
  font-size: 12px;
  color: #818181;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 240px; }

.list-item-action-left,
.list-item-action-right {
  position: relative;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center; }

.list-item-action-left {
  margin-left: -18px; }

.list-item-action-right {
  margin-right: -18px; }
