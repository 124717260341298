@import '../../../../../../../variables';
.stampbox {
  margin-top: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
}
.stampbox .stamp_container {
  align-self: center;
}

.stamp_container {
  display: flex;
  margin: 10px 0;
  background-color: $primary-color;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 90%;
  border-radius: 5px;
  cursor: pointer;
  border: 4px solid #efeff4;
}

.stamp_container:hover {
  box-shadow: 0px 0px 20px $primary-color-light;
  border-color: $primary-color-light;
}

.stamp {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: $secondary-color;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: 0 10px;
  color: $primary-color;
  font-weight: 900;
}

.stamp_container_active {
  border-color: $primary-color-dark;
}

.stamp_container_active:hover {
  border-color: $primary-color-dark !important;
}
.stampbox_heading {
  margin: 0;
  color: $primary-color;
  font-weight: 700;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  text-align: left;
}

.logos_container {
  position: relative;
  width: 90%;
  height: 350px;
  overflow: scroll;
  background-color: white;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px 0;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
}

.logos_container > img {
  object-fit: contain;
  width: 100px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s;
}

.logos_container > img:hover {
  transform: scale(1.4);
}
