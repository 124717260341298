@import '../../../../../variables';

.capitilize {
  text-transform: capitalize;
}

.dateModal {
  display: grid;
  place-items: center;
}

.modalContainer {
  background-color: #fff;
  width: 80vw;
  height: 35vh;
  display: flex;
  align-items: center;
}

.modalLeft {
  margin-left: 6vw;
  height: 100%;
}
.modalRight {
  margin-left: 6vw;
  height: 100%;
}

.filterBtn {
  margin: 24px 0;
}

.dateHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success {
  color: $success-color;
}
.error {
  color: $error-color;
}

.headerLabel {
  font-family: $default-font;
  font-weight: 600;
  font-size: 20px;
  position: relative;
  top: 6px;
  padding: 0 8px;
}

.scrollHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
