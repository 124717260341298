.app-link-menu-wrapper {
  display: block;
  width: 100%;
  padding-top: 10px;

  .app-link-container {
    display: flex;
    padding: 0;
    align-items: stretch;
    justify-items: stretch;

    .link-radio-option-wrapper {
      display: inline-block;
      width: 278px;

      .select-field-wrapper {
        width: 100%;
        padding-left: 6px;
        padding-right: 6px;
        margin: 0;
      }
    }

    .radio-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      & > div {
        top: 10px;
      }
    }
  }
}

.radio-select-setting {
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  margin-bottom: 16px;
}
